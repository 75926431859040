import React, { useState } from "react";
import { Box, MenuItem, Menu, Button, ButtonGroup } from "@mui/material";
import { Link } from "react-router-dom";
import { getJwt } from "../../services/authService";
import { mark_as_done, openInNewTab, displayWithIcon, get_api_url } from "../utils";
import { Create } from "@mui/icons-material";

export function FicheChaponnageStatus({ commande, restricted }) {
    const [done, set_done] = useState(commande?.fiche_chaponnage_done);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const set_fiche_chaponnage_done = (value) => {
        set_done(value);
        mark_as_done("fiche_chaponnage", commande, value);
        commande.fiche_chaponnage_done = value;
        handleClose();
    };

    const fiche_done = () => {
        return done || commande?.fiche_chaponnage?.id;
    };

    return !commande?.id ? (
        <div></div>
    ) : (
        <div>
            <Button onClick={handleClick} endIcon={fiche_done() ? displayWithIcon(1) : <Create />} variant="outlined">
                Fiche chaponnage
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {fiche_done() ? (
                    <React.Fragment>
                        {!commande.fiche_chaponnage_done && (
                            <MenuItem onClick={handleClose} component={Link} to={`/fiche-chaponnage/photos/${commande.fiche_chaponnage.id}`}>
                                Ajouter des photos
                            </MenuItem>
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <MenuItem onClick={handleClose} component={Link} to={`/fiche-chaponnage/edit/?ids_commande=${commande?.id}`}>
                            Faire la fiche chaponnage
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                set_fiche_chaponnage_done(true);
                            }}
                        >
                            Marquer comme faite
                        </MenuItem>
                    </React.Fragment>
                )}
                {commande.fiche_chaponnage_done && (
                    <MenuItem
                        onClick={() => {
                            set_fiche_chaponnage_done(false);
                        }}
                    >
                        Marquer comme non faite
                    </MenuItem>
                )}

                {!restricted && commande?.fiche_chaponnage?.id && (
                    <React.Fragment>
                        <MenuItem onClick={handleClose} component={Link} to={`/fiche-chaponnage/edit/${commande?.fiche_chaponnage?.id}`}>
                            Voir
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                const url = `${get_api_url()}/pdfs/${commande?.fiche_chaponnage?.id}?token=${getJwt()}`;
                                openInNewTab(url);
                            }}
                        >
                            Voir le PDF
                        </MenuItem>
                    </React.Fragment>
                )}
            </Menu>
        </div>
    );
}
