import React from "react";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import MobileDatePicker from "@mui/lab/MobileDatePicker";

export function DatePickerInput({ control, name, label, ...props }) {
	return (
		<Controller
			name={name}
			control={control}
			defaultValue={null}
			render={({ field: { onChange, value }, fieldState: { error } }) => {

				return (
					<MobileDatePicker
						label={label}
						disableCloseOnSelect={false}
						cancelText={"Annuler"}
						todayText={"Aujourd'hui"}
						inputFormat="ddd DD/MM/yyyy"
						value={value}
						showTodayButton
						onChange={onChange}
						renderInput={(params) => (
							<TextField {...params} {...props} />
						)}
					/>
				);
			}}
		/>
	);
}
