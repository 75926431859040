import http from "./httpService";
import {get_api_url } from "../components/utils"


const apiEndpoint = get_api_url() + "/campings";

function campingUrl(id) {
	return `${apiEndpoint}/${id}/`;
}

export function getNextNumCamping() {
	return http.post(`${get_api_url()}/next-camping-num/`)
}
export function getCampings() {
	return http.get(`${apiEndpoint}/?ordering=-code`);
}

export function getCamping(CampingId) {
	return http.get(campingUrl(CampingId));
}

export function getCampingWithCode(campingCode) {
	return http.get(apiEndpoint + "/?code=" + campingCode);
}

export function saveCamping(camping) {
	if (camping.id) {
		const body = { ...camping };
		return http.put(campingUrl(camping.id), body);
	}

	return http.post(apiEndpoint + "/", camping);
}

export function deleteCamping(campingId) {
	return http.delete(campingUrl(campingId));
}
