import React from "react";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import DateTimePicker from "@mui/lab/DateTimePicker";
import MobileDateTimePicker from "@mui/lab/MobileDateTimePicker";
import moment from "moment";

export function DateTimePickerInput({ control, name, label, disabled, ...props }) {
    return (
        <Controller
            name={name}
            defaultValue={null}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
                if (!value) {
                    value = moment().format("YYYY-MM-DDT00:00");
                }

                return (
                    <MobileDateTimePicker
                        label={label}
                        value={value}
                        cancelText={"Annuler"}
                        todayText={"Aujourd'hui"}
                        showTodayButton
                        inputFormat="ddd DD/MM/yyyy HH:mm"
                        onChange={onChange}
                        disabled={disabled}
                        renderInput={(params) => <TextField {...params} {...props} />}
                    />
                );
            }}
        />
    );
}
