import React, { useEffect, useState } from "react";
import {
    Grid,
    Box,
    Dialog,
    DialogContent,
    DialogContentText,
    Table,
    TableBody,
    TableContainer,
    Paper,
    AppBar,
    Toolbar,
    Alert,
    TableHead,
    TableRow,
    TableCell,
    DialogTitle,
    IconButton,
    Button,
    Typography,
    AlertTitle,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";
import { ExpandMore } from "@mui/icons-material";
import { openInNewTab, format_tel_number_link, display_row } from "../utils";
import { toast } from "react-toastify";
import { delete_ticket } from "../../services/ticketSerivce";
import { getAlbum } from "../../services/albumService";
import { Close } from "@mui/icons-material";
import { getCampings } from "../../services/campingService";
import { FaWaze } from "react-icons/fa";
import { SiGooglemaps } from "react-icons/si";
import { getDistance } from "geolib";

export default function FindCamping({ points_cardinaux, set_points_cardinaux }) {
    const [campings, set_campings] = useState([]);

    useEffect(() => {
        async function find_campings() {
            let tmp_arr = points_cardinaux.split(",");
            if (tmp_arr.length !== 2) {
                set_points_cardinaux(null);
                return;
            }
            const latitude = tmp_arr[0];
            const longitude = tmp_arr[1];
            try {
                const { data: campings_found } = await getCampings();
                campings_found.map((camping) => {
                    camping.distance = getDistance(
                        { latitude: latitude, longitude: longitude },
                        { latitude: camping.latitude, longitude: camping.longitude }
                    );
                });
                set_campings(campings_found.sort((a, b) => a.distance > b.distance));
                console.log(campings_found.sort((a, b) => a.distance > b.distance));
            } catch (e) {
                console.log(e);
                toast.error("Impossible de récuperer les campings");
            }
        }

        if (points_cardinaux) find_campings();
    }, [points_cardinaux]);

    return (
        <Dialog
            open={points_cardinaux}
            onClose={() => set_points_cardinaux(null)}
            fullScreen
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => set_points_cardinaux(null)} aria-label="close">
                        <Close />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogTitle id="alert-dialog-title">Trouver un camping proche</DialogTitle>
            <DialogContent>
                <TableContainer component={Paper} sx={{ mt: 3 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Camping</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {campings?.length > 0 &&
                                campings.map((camping) => (
                                    <TableRow>
                                        <TableCell align="center">
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} md={12}>
                                                    <Typography>
                                                        {camping.nom} ({camping.distance / 1000} km)
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    {format_tel_number_link(camping?.telephone)}
                                                    {camping.portable ? <span> - {format_tel_number_link(camping?.portable)} </span> : ""}
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    {camping.adresse}
                                                </Grid>
                                                <Grid item xs={6} md={6}>
                                                    <Button
                                                        startIcon={<SiGooglemaps />}
                                                        variant="outlined"
                                                        onClick={() => {
                                                            openInNewTab(
                                                                `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${camping?.points_cardinaux}`
                                                            );
                                                        }}
                                                    >
                                                        Google Maps
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6} md={6}>
                                                    <Button
                                                        startIcon={<FaWaze />}
                                                        edge="start"
                                                        variant="outlined"
                                                        onClick={() => {
                                                            openInNewTab(
                                                                `https://www.waze.com/ul?ll=${camping?.points_cardinaux.replace(
                                                                    ",",
                                                                    "%2C"
                                                                )}&navigate=yes&zoom=17`
                                                            );
                                                        }}
                                                    >
                                                        Waze
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
        </Dialog>
    );
}
