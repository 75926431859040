import React from "react";
import { Controller } from "react-hook-form";
import { Switch, FormGroup, FormControlLabel } from "@mui/material";

export function SwitchInput({ control, name, label, ...props }) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch label={label} checked={value} value={value} onChange={onChange} {...props} />}
                            label={label}
                        />
                    </FormGroup>
                );
            }}
        />
    );
}
