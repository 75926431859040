import http from "./httpService";
import {get_api_url } from "../components/utils"



const apiEndpoint = get_api_url() + "/send-sms";


export function send_sms(recipient, content) {
    return http.post(apiEndpoint, {recipient, content});
}