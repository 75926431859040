
const possible_densities = ["compact", "standard", "comfortable"]

export function on_state_change(event) {
    let current_density = localStorage.getItem("density");
    if (current_density !== event?.density?.value && possible_densities.includes(event?.density?.value))
    {
        localStorage.setItem("density", event.density.value);
    }
}

export function get_density()
{
    try {
        return localStorage.getItem("density");
    }catch(e) {
        return "standard"
    }
}