import http from "./httpService";
import {getJwt, logout} from "./authService";
import {get_api_url } from "../components/utils"

import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";

const apiEndpoint = get_api_url() + "/saisons";

export function getSaisonYear(year) {
    return http.get(`${apiEndpoint}/?year=${year}`);
}

export function getCurrentSaison() {
    try {
        const jwt_decoded = jwtDecode(getJwt())
        if (!jwt_decoded.current_saison) throw new Error("Current saison null")
        return jwt_decoded.current_saison

    } catch (ex) {
        toast.error("Cannot get current saison");
        logout()
        return null
    }
}

function saisonUrl(id) {
    return `${apiEndpoint}/${id}/`;
}

export async function getSaisons(force=false) {
    if(force) return http.get(`${apiEndpoint}/?ordering=-year`);
    try {
        const jwt_decoded = jwtDecode(getJwt())
        if (!jwt_decoded) throw new Error("Impossible to decode JWT.saisons")
        return jwt_decoded.saisons
    } catch (ex) {
        toast.error("Cannot get saisons");
        logout()
        return null
    }
}

export function getSaison(saisonId) {
    const url = saisonUrl(saisonId);
    return http.get(url);
}

export function saveSaison(saison) {
    if (saison.id) {
        const body = { ...saison };

        let res;
        try {
            res = http.put(saisonUrl(saison.id), body);
        } catch (error) {
            console.error(error.response.data); // NOTE - use "error.response.data` (not "error")
        }

        return res;
    }

    return http.post(`${apiEndpoint}/`, saison);
}

export function deleteSaison(saisonId) {
    return http.delete(saisonUrl(saisonId));
}
