import React, { useState, useEffect, lazy } from "react";
import { Route, Switch, Redirect, Link, NavLink, Routes, Navigate } from "react-router-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "react-toastify/dist/ReactToastify.css";
import { AppBar, CssBaseline, Drawer, Container, Menu, MenuItem, IconButton, Typography, Box, Toolbar, Avatar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import ProtectedRoute from "./components/common/protectedRoute";
import ProtectedModRoute from "./components/common/protectedModRoute";
import ProtectedAdminRoute from "./components/common/protectedAdminRoute";
import LoginForm from "./components/loginForm";
import { getCurrentSaison } from "./services/saisonService";
import { getCurrentUser } from "./services/authService";
import { Homepage } from "./components/homepages/homepage";
import { HomepageAdmin } from "./components/homepages/homepageAdmin";
import { DrawerAdmin } from "./components/drawers/drawer_admin";
import Test from "./components/testImageForm";

const Erreur = lazy(() => import("./components/error"));
const PersonnelForm = lazy(() => import("./components/personnels/personnelForm"));
const CommandeForm = lazy(() => import("./components/commandes/commandeForm"));
const ValidateCommandeClient = lazy(() => import("./components/commandes/validate_commande_client"));

const FicheFinElevage = lazy(() => import("./components/fin_elevage/fiche_fin_elevage"));
const FicheFinElevageForm = lazy(() => import("./components/fin_elevage/fiche_fin_elevage_form"));

const Rdv = lazy(() => import("./components/rendez_vous/rendez_vous"));
const ViewRdv = lazy(() => import("./components/rendez_vous/view_rendez_vous"));
const RdvForm = lazy(() => import("./components/rendez_vous/rendez_vous_form"));
const RendezVousResponse = lazy(() => import("./components/rendez_vous/rendez_vous_response"));
const MyRendezVous = lazy(() => import("./components/rendez_vous/my_rendez_vous"));

const ClientForm = lazy(() => import("./components/clients/clientForm"));
const Personnels = lazy(() => import("./components/personnels/personnels"));
const Commandes = lazy(() => import("./components/commandes/commandes"));
const CampingForm = lazy(() => import("./components/campings/campingForm"));
const FicheChaponnage = lazy(() => import("./components/ficheChaponnage/ficheChaponnage"));
const FicheHoraire = lazy(() => import("./components/ficheHoraire/ficheHoraire"));
const FicheVisiteForm = lazy(() => import("./components/postChaponnage/visiteForm"));
const FicheVisite = lazy(() => import("./components/postChaponnage/fiche_visite"));

const PhotosChef = lazy(() => import("./components/ficheChaponnage/photos_chef"));
const Parameters = lazy(() => import("./components/common/parameters"));
const DeplacementForm = lazy(() => import("./components/deplacement/deplacementForm"));
const ViewPlanning = lazy(() => import("./components/deplacement/viewPlanning"));
const ViewPlanningPersonnel = lazy(() => import("./components/deplacement/viewPlanningPersonnel"));
const Appels = lazy(() => import("./components/appels/appels"));
const ViewMePlanning = lazy(() => import("./components/me/viewMePlanning"));
const HoraireForm = lazy(() => import("./components/ficheHoraire/horaireForm"));
const ViewPlanningCamion = lazy(() => import("./components/camions/viewPlanningCamion"));
const FindEquipe = lazy(() => import("./components/equipes/find_equipe"));
const Clients = lazy(() => import("./components/clients/clients"));
const ViewClient = lazy(() => import("./components/clients/viewClient"));
const ViewCamping = lazy(() => import("./components/campings/viewCamping"));
const Saisons = lazy(() => import("./components/saisons/saisons"));
const Me = lazy(() => import("./components/me/me"));
const Logout = lazy(() => import("./components/logout"));
const Campings = lazy(() => import("./components/campings/campings"));
const ViewPersonnel = lazy(() => import("./components/personnels/viewPersonnel"));
const ViewCommande = lazy(() => import("./components/commandes/viewCommande"));
const Prospection = lazy(() => import("./components/prospection/prospection"));
const Groupements = lazy(() => import("./components/groupements/groupements"));
const ViewGroupement = lazy(() => import("./components/groupements/viewGroupement"));
const GroupementForm = lazy(() => import("./components/groupements/groupementForm"));
const Techniciens = lazy(() => import("./components/techniciens/techniciens"));
const ViewTechnicien = lazy(() => import("./components/techniciens/viewTechnicien"));
const TechnicienForm = lazy(() => import("./components/techniciens/technicienForm"));
const Prix = lazy(() => import("./components/prix/prix"));
const PrixForm = lazy(() => import("./components/prix/prixForm"));
const Equipe = lazy(() => import("./components/equipes/equipes"));
const EquipeForm = lazy(() => import("./components/equipes/equipeForm"));
const ViewEquipe = lazy(() => import("./components/equipes/viewEquipe"));
const Camion = lazy(() => import("./components/camions/camions"));
const CamionForm = lazy(() => import("./components/camions/camionForm"));
const ViewCamion = lazy(() => import("./components/camions/viewCamion"));
const Pince = lazy(() => import("./components/pinces/pinces"));
const PinceForm = lazy(() => import("./components/pinces/pinceForm"));
const ViewPince = lazy(() => import("./components/pinces/viewPince"));
const ZoneForm = lazy(() => import("./components/zones/zoneForm"));
const ViewZone = lazy(() => import("./components/zones/viewZone"));
const Zones = lazy(() => import("./components/zones/zones"));
const SoucheForm = lazy(() => import("./components/souches/soucheForm"));
const ViewSouche = lazy(() => import("./components/souches/viewSouche"));
const Souches = lazy(() => import("./components/souches/souches"));
const Bons = lazy(() => import("./components/bonsFacturation/bons"));
const Horaires = lazy(() => import("./components/horaires/horaires"));
const Remunerations = lazy(() => import("./components/remunerations/remunerations"));
const RemunerationForm = lazy(() => import("./components/remunerations/remunerationForm"));
const ViewRemuneration = lazy(() => import("./components/remunerations/viewRemuneration"));
const ViewHoraires = lazy(() => import("./components/ficheHoraire/viewHoraires"));
const SaisonForm = lazy(() => import("./components/saisons/saisonForm"));
const ChecklistChef = lazy(() => import("./components/checklists/chefs/checklist_chef"));
const Checklist = lazy(() => import("./components/checklists/checklists"));
const Users = lazy(() => import("./components/users/users"));
const CamionAmende = lazy(() => import("./components/camions/camionAmende"));
const BonsForm = lazy(() => import("./components/bonsFacturation/bonsForm"));
const Statistiques = lazy(() => import("./components/statistiques/statistiques"));
const Ideas = lazy(() => import("./components/ideas/ideas"));
const IdeaForm = lazy(() => import("./components/ideas/ideaForm"));
const ChecklistForm = lazy(() => import("./components/checklists/checklistForm"));
const ObservationPlanningForm = lazy(() => import("./components/personnels/observationPlanningForm"));
const PasswordForm = lazy(() => import("./components/passwordForm"));
const Ticket = lazy(() => import("./components/tickets/tickets"));
const TicketForm = lazy(() => import("./components/tickets/ticket_form"));
const ResetPassword = lazy(() => import("./components/users/reset_password"));

const drawerWidth = 245;
const transitionDuration = 500;
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: "none",
    },
    appBar: {
        zIndex: 99,
    },
    drawer: {
        width: 600,
        "& .MuiBackdrop-root": {
            display: "none",
        },
    },
    drawerContainer: {
        overflow: "auto",
    },
    linkChapi: {
        textDecoration: "none",
        color: "black",
        "& .MuiLink-root": {
            textDecoration: "none",
        },
    },
    content: {
        flexGrow: 4,
        marginTop: 5,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: transitionDuration,
        }),
        marginLeft: 0,
    },
}));

export default function App() {
    const classes = useStyles();
    const [user, setUser] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [saison, setSaison] = useState();
    const [open, setOpen] = useState(window.innerWidth > 650);
    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const menuId = "primary-search-account-menu";
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem component={Link} to="/me" onClick={handleMenuClose}>
                Mon compte
            </MenuItem>
            <MenuItem component={Link} to="/rdvs/my" onClick={handleMenuClose}>
                Mes rendez-vous
            </MenuItem>
            <MenuItem component={Link} to="/change-password" onClick={handleMenuClose}>
                Changer mon mot de passe
            </MenuItem>
            <MenuItem
                component={Link}
                to="/logout"
                onClick={() => {
                    handleMenuClose();
                    setUser(null);
                    setOpen(false);
                }}
            >
                Déconnexion
            </MenuItem>
        </Menu>
    );

    useEffect(() => {
        async function getUser() {
            try {
                const u = getCurrentUser();
                if (u) {
                    setUser(u);
                    const saisonFound = await getCurrentSaison();
                    setSaison(saisonFound);
                }
            } catch (e) {
                toast.error("Erreur");
            }
        }

        getUser();
    }, []);

    return (
        <div className={classes.root} style={{ width: "100%" }}>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Toolbar>
                        {user && (
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                sx={{ mr: 2 }}
                                onClick={() => setOpen(!open)}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}

                        <Typography variant="h6" noWrap element="div" sx={{ display: { xs: "block", sm: "block" } }}>
                            {user ? (
                                <NavLink
                                    to="/"
                                    style={{
                                        textDecoration: "none",
                                        color: "unset",
                                    }}
                                >
                                    Chapi - Saison {saison?.year}
                                </NavLink>
                            ) : (
                                <span>Chaponord</span>
                            )}
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        {user && (
                            <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                                <IconButton
                                    size="large"
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    onClick={handleProfileMenuOpen}
                                    color="inherit"
                                >
                                    <Avatar sx={{ bgcolor: "green" }}>
                                        {user.username.split(".")[0][0].toUpperCase()}
                                        {user.username.split(".")[1][0].toUpperCase()}
                                    </Avatar>
                                </IconButton>
                            </Box>
                        )}
                    </Toolbar>
                </AppBar>

                {renderMenu}

                {open && user && (
                    <Drawer
                        variant="permanent"
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            [`& .MuiDrawer-paper`]: {
                                width: drawerWidth,
                                boxSizing: "border-box",
                            },
                        }}
                    >
                        <Toolbar />
                        <Box sx={{ overflow: "auto" }}>
                            <DrawerAdmin />
                        </Box>
                    </Drawer>
                )}
                <Box element="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Container maxWidth="xl" className={classes.content} disableGutters={true}>
                        <Toolbar />
                        <Routes>

                            <Route path="/reset-password/:uid/:token" element={<ResetPassword />} />
                            <Route path="/error" element={<Erreur />} />
                            <Route path="/logout" element={<Logout />} />
                            <Route path="/login" element={<LoginForm />} />
                            <Route path="/change-password" element={<PasswordForm />} />
                            <Route exact path="/" element={<Navigate replace to="/homepage" />} />

                            <Route exact path="/" element={<ProtectedAdminRoute />}>
                                <Route path="/clients/edit/:id" element={<ClientForm />} />
                                <Route path="/clients/view/:id" element={<ViewClient />} />
                                <Route path="/clients" element={<Clients />} />
                                <Route path="/campings/view/:id" element={<ViewCamping />} />
                                <Route path="/users" element={<Users />} />
                                <Route path="/campings/edit/:id" element={<CampingForm />} />
                                <Route path="/campings" element={<Campings />} />
                                <Route path="/fiches-fin-elevage/:saison?" element={<FicheFinElevage />} />
                                <Route path="/commandes/saison/:saison?" element={<Commandes />} />
                                <Route path="/commandes/edit/:id" element={<CommandeForm />} />
                                <Route path="/commandes/view/:id" element={<ViewCommande />} />
                                <Route path="/statistiques/:saison" element={<Statistiques />} />
                                <Route path="/bons-facturation/saison/:saison?" element={<Bons />} />
                                <Route path="/horaires/saison/:saison?" element={<Horaires />} />
                                <Route path="/parameters" element={<Parameters />} />
                                <Route path="/horaires/edit/:id" element={<HoraireForm />} />
                                <Route path="/techniciens/edit/:id" element={<TechnicienForm />} />
                                <Route path="/techniciens/view/:id" element={<ViewTechnicien />} />
                                <Route path="/techniciens" element={<Techniciens />} />
                                <Route path="/horaires/fiche/:id" element={<ViewHoraires />} />
                                <Route path="/observations-planning/:id" element={<ObservationPlanningForm />} />
                                <Route path="/rdvs/view/:id" element={<ViewRdv />} />
                                <Route path="/rdvs/edit/:id" element={<RdvForm />} />
                                <Route path="/rdvs" element={<Rdv />} />
                                <Route path="/tickets/saison/:saison" element={<Ticket />} />
                                <Route path="/personnels/view/:id" element={<ViewPersonnel />} />
                                <Route path="/fiches-visite/:saison?" element={<FicheVisite />} />
                                <Route path="/checklists/edit/:id" element={<ChecklistForm />} />
                                <Route path="/checklists" element={<Checklist />} />
                                <Route path="/ideas" element={<Ideas />} />
                                <Route path="/homepage-admin" element={<HomepageAdmin />} />
                                <Route path="/prospection/saison/:saison?" element={<Prospection />} />
                                <Route path="/saisons/edit/:id" element={<SaisonForm />} />
                                <Route path="/saisons" element={<Saisons />} />
                                <Route path="/deplacement/edit/:id" element={<DeplacementForm />} />
                                <Route path="/planning/:saison" element={<ViewPlanning />} />
                                <Route path="/planning-personnel/:saison" element={<ViewPlanningPersonnel />} />
                                <Route path="/groupements/edit/:id" element={<GroupementForm />} />
                                <Route path="/groupements/view/:id" element={<ViewGroupement />} />
                                <Route path="/groupements" element={<Groupements />} />
                                <Route path="/pinces/edit/:id" element={<PinceForm />} />
                                <Route path="/pinces/view/:id" element={<ViewPince />} />
                                <Route path="/pinces" element={<Pince />} />
                                <Route path="/remunerations/edit/:id" element={<RemunerationForm />} />
                                <Route path="/remunerations/view/:id" element={<ViewRemuneration />} />
                                <Route path="/remunerations/saison/:saison?" element={<Remunerations />} />
                                <Route path="/equipes/position" element={<FindEquipe />} />
                                <Route path="/equipes/edit/:id" element={<EquipeForm />} />
                                <Route path="/equipes/view/:id" element={<ViewEquipe />} />
                                <Route path="/equipes/saison/:saison?" element={<Equipe />} />
                                <Route path="/prix/edit/:id" element={<PrixForm />} />
                                <Route path="/prix/saison/:saison?" element={<Prix />} />
                                <Route path="/camions/edit/:id" element={<CamionForm />} />
                                <Route path="/camions/view/:id" element={<ViewCamion />} />
                                <Route path="/camions/saison/:saison?" element={<Camion />} />
                                <Route path="/zones/edit/:id" element={<ZoneForm />} />
                                <Route path="/zones/view/:id" element={<ViewZone />} />
                                <Route path="/zones" element={<Zones />} />
                                <Route path="/appels/saison/:saison?" element={<Appels />} />
                                <Route path="/camions/amende/:saison" element={<CamionAmende />} />
                                <Route path="/souches/edit/:id" element={<SoucheForm />} />
                                <Route path="/souches/view/:id" element={<ViewSouche />} />
                                <Route path="/souches" element={<Souches />} />
                            </Route>

                            <Route exact path="/" element={<ProtectedModRoute />}>
                                <Route path="/bons-facturation/new/:saison/:id_commande?" element={<BonsForm />} />
                                <Route path="/personnels/saison/:saison?" element={<Personnels />} />
                                <Route path="/fiche-chaponnage/edit/:id?" element={<FicheChaponnage />} />
                                <Route path="/fiche-chaponnage/photos/:id_fiche" element={<PhotosChef />} />
                                <Route path="/checklists/chef/appel" element={<ChecklistChef type="APPEL" />} />
                                <Route path="/checklists/chef/camion" element={<ChecklistChef type="CAMION" />} />
                                <Route path="/checklists/chef/camping" element={<ChecklistChef type="CAMPING" />} />
                                <Route path="/ideas/edit/:id" element={<IdeaForm />} />
                                <Route path="/fiche-horaire/edit" element={<FicheHoraire />} />
                                <Route path="/planning-camions/:saison" element={<ViewPlanningCamion />} />
                                <Route path="/tickets/edit/:saison/:id" element={<TicketForm />} />
                            </Route>

                            <Route exact path="/" element={<ProtectedRoute />}>
                                {/* <Route path="/homepage-chef" element={<HomepageMod />} /> */}
                                <Route path="/me/planning" element={<ViewMePlanning />} />
                                <Route path="/me" element={<Me />} />
                                <Route path="/rdvs/my" element={<MyRendezVous />} />
                                <Route path="/personnels/edit/:id" element={<PersonnelForm />} />
                                <Route path="/homepage" element={<Homepage />} />
                            </Route>

                            <Route path="/fiches-fin-elevage/edit/:id" element={<FicheFinElevageForm />} />
                            <Route path="/commandes/validate" element={<ValidateCommandeClient />} />
                            <Route path="/test" element={<Test />} />
                            <Route path="/rdvs/r/:uuid_invitation" element={<RendezVousResponse />} />
                            <Route path="/fiches-visite/edit/:id?" element={<FicheVisiteForm />} />
                            <Route path="*"  element={<Erreur />} />
                        </Routes>
                    </Container>
                </Box>
            </Box>
        </div>
    );
}
