import React from "react";
import { Controller } from "react-hook-form";
import { TextField, Autocomplete } from "@mui/material";

export function AutocompleteInput({
	control,
	name,
	label,
	children,
	displayed_field = "label",
	equal_field = null,
	...props
}) {
	return (
		<Controller
			name={name}
			defaultValue={null}
			control={control}
			render={({ field: { onChange, value }, fieldState: { error } }) => {
				return (
					<Autocomplete
						value={value}
						onChange={(e, data) => onChange(data)}
						getOptionLabel={(option) => {
							let option_label = option[displayed_field];
							if (option_label) {
								return option_label.toString();
							}
							return "";
						}}
						noOptionsText="Aucune option"
						clearText="Supprimer"
						closeText="Fermer"
						openText="Ouvrir"
						isOptionEqualToValue={(option, value) =>
							option?.id === value?.id
						}
						options={children}
						{...props}
						renderInput={(params) => (
							<TextField {...params} {...props} label={label} />
						)}
					/>
				);
			}}
		/>
	);
}
