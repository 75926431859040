import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import auth from "../../services/authService";

const ProtectedAdminRoute = () => {
    const user = auth.getCurrentUser();
    return user?.is_superuser ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedAdminRoute;
