import http from "./httpService";
import {get_api_url } from "../components/utils"


const apiEndpoint = `${get_api_url()}/equipes`;

function equipeUrl(id) {
    return `${apiEndpoint}/${id}`;
}

export function getEquipes(year = null) {
    if (year) {
        return http.get(`${apiEndpoint}/?ordering=id&saison__year=${year}`);
    }
    return http.get(apiEndpoint);
}

export function getEquipesCurrentSaison() {
    return http.get(`${apiEndpoint}/?ordering=id&saison__current_saison=true`);
}

export function getEquipe(equipeId) {
    return http.get(equipeUrl(equipeId));
}

export function saveEquipe(equipe) {
    if (equipe.id) {
        const body = { ...equipe };
        return http.put(`${equipeUrl(equipe.id)}/`, body);
    }

    return http.post(`${apiEndpoint}/`, equipe);
}

export function deleteEquipe(equipeId) {
    return http.delete(equipeUrl(equipeId));
}
