import http from "./httpService";
import {get_api_url } from "../components/utils"

import axios from "axios";
import moment from "moment"
const apiEndpoint = get_api_url() + "/commandes";

function commandeUrl(id) {
	return `${apiEndpoint}/${id}/`;
}


export function send_mail_fin_elevage(id) {
	return http.post(`${get_api_url()}/send-mail-fin-elevage`, id)
}

export function send_mail_fiche_visite(id) {
	return http.post(`${get_api_url()}/send-mail-fiche-visite`, id)
}

export function getClientCommande(idClient, saisonYear) {
	return http.get(
		apiEndpoint + "/?client=" + idClient + "&saison__year=" + saisonYear
	);
}

export function get_commande_to_confirm(commande_uuid, client_uuid) {
	return axios.get(`${get_api_url()}/confirm-commande?commande=${commande_uuid}&client=${client_uuid}`);
}

export function save_commande_to_confirm(datas) {
	return axios.post(`${get_api_url()}/confirm-commande`, datas);
}

export function getCommandes(saison = null) {
	if (saison) {
		return http.get(
			apiEndpoint + "/?ordering=numero_commande&saison__year=" + saison
		);
	}
	return http.get(apiEndpoint + "/?ordering=numero_commande");
}

export function getCommande(CommandeId) {
	return http.get(commandeUrl(CommandeId));
}

export function saveCommande(commande) {
	if (commande.id) {
		const body = { ...commande };
		return http.put(commandeUrl(commande.id), body);
	}

	return http.post(`${apiEndpoint}/`, commande);
}

export function deleteCommande(commandeId) {
	return http.delete(commandeUrl(commandeId));
}

export function getCommandeSaisonNumero(saison_year, numero) {
	return http.get(
		apiEndpoint +
			"/?numero_commande=" +
			numero +
			"&saison__year=" +
			saison_year
	);
}
