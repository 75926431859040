import React, { useState, useEffect } from "react";
import {
    Grid,
    Typography,
    Box,
    Tabs,
    Tab,
    Accordion,
    ButtonGroup,
    AccordionDetails,
    AccordionSummary,
    Divider,
    Card,
    CardContent,
    CardActions,
    Button,
    Table,
    TableRow,
    Tooltip,
    IconButton,
    CardHeader,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    TableBody,
    Menu,
    MenuItem,
    Alert,
    ListItemIcon,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TableContainer,
    Paper,
} from "@mui/material";
import { ExpandMore, MoreVert, ArrowBack, SpeakerNotes, Navigation, Create, Check, LinkOutlined } from "@mui/icons-material";
import { format_tel_number_link, displayWithIcon, display_row, display_equipe, openInNewTab } from "../utils";
import { Link } from "react-router-dom";
import { FicheChaponnageStatus } from "../common/FicheChaponnageStatus";
import { FicheHoraireStatus } from "../common/FicheHoraireStatus";
import { getCurrentSaison } from "../../services/saisonService";
import { getFicheCommande } from "../../services/ficheChaponnageService";
import { getBonCommande } from "../../services/bonFacturationService";

export default function CommandeAdministratif({ commande, is_visite }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [bon_facturation, set_bon_facturation] = useState();
    const [fiche_chaponnage, set_fiche_chaponnage] = useState();
    const [show_bon, set_show_bon] = useState(false);

    useEffect(() => {
        async function fetch_commande_data() {
            try {
                const { data: fiche_chaponnage_found } = await getFicheCommande(commande.id);
                if (fiche_chaponnage_found?.length > 0) set_fiche_chaponnage(fiche_chaponnage_found[0]);

                const { data: bon_facturation_found } = await getBonCommande(commande.id);
                if (bon_facturation_found?.length > 0) set_bon_facturation(bon_facturation_found[0]);
            } catch (e) {
                console.log(e);
            }
        }

        if (commande?.id) fetch_commande_data();
    }, [commande]);

    return (
        <Grid container spacing={1} justifyContent="center" alignItems="center" direction="column">
            <Grid item xs={12} md={6}>
                {is_visite ? (
                    fiche_chaponnage ? (
                        <Button component={Link} to={`/fiche-chaponnage/edit/${commande.id}`} variant="outlined" endIcon={displayWithIcon(1)}>
                            Voir la fiche chaponnage
                        </Button>
                    ) : (
                        <Button disabled>Fiche chaponnage non faite</Button>
                    )
                ) : (
                    <FicheChaponnageStatus
                        restricted={true}
                        commande={{
                            id: commande.id,
                            fiche_chaponnage: fiche_chaponnage,
                            fiche_chaponnage_done: commande.fiche_chaponnage_done,
                        }}
                        mask_as_done={true}
                    />
                )}
            </Grid>
            <Grid item xs={12} md={6}>
                {bon_facturation ? (
                    <Button variant="outlined" endIcon={displayWithIcon(1)} color="secondary" onClick={() => set_show_bon(true)}>
                        Voir le bon de facturation
                    </Button>
                ) : (
                    <Button
                        component={Link}
                        to={`/bons-facturation/new/${getCurrentSaison().year}/${commande.id}`}
                        variant="outlined"
                        endIcon={<Create />}
                    >
                        Faire le bon de facturation
                    </Button>
                )}
            </Grid>
            <Grid item xs={12} md={6}>
                {!is_visite && <FicheHoraireStatus mask_as_done={true} restricted={true} commande={commande} />}
            </Grid>
            {is_visite && (
                <Grid item xs={12} md={6}>
                    (commande?.fiche_visite ? (
                    <Button component={Link} to={`/fiches-visite/edit/${commande.fiche_visite}`} variant="outlined" endIcon={displayWithIcon(1)}>
                        Voir la fiche visite
                    </Button>
                    ) : (
                    <Button
                        component={Link}
                        to={`/fiches-visite/edit/new?commande=${commande.uuid}&client=${commande.client.uuid}`}
                        variant="outlined"
                        endIcon={<Create />}
                    >
                        Faire la fiche visite
                    </Button>
                    ))
                </Grid>
            )}

            <Dialog open={show_bon} onClose={() => set_show_bon(false)}>
                <DialogTitle>Bon de facturation de la commande n°{commande?.numero_commande}</DialogTitle>
                <DialogContent>
                    {!fiche_chaponnage && (
                        <Alert variant="outlined" severity="info" sx={{ my: 1.5 }}>
                            Fiche chaponnage manquante
                        </Alert>
                    )}
                    {bon_facturation && (
                        <TableContainer component={Paper} sx={{ maxWidth: 900 }}>
                            <Table sx={{ maxWidth: 900 }} aria-label="simple table" size="small">
                                <TableBody>
                                    {display_row("Passés sur table", bon_facturation.nb_passes_table)}
                                    {display_row("Femelles", bon_facturation.nb_femelles)}
                                    {display_row("Morts", bon_facturation.nb_morts)}
                                    {display_row("Nombre total de chapons", bon_facturation.nb_chapons)}
                                    {bon_facturation.commande.prix_status !== 2 && (
                                        <React.Fragment>
                                            {display_row("Prix unitaire", `${bon_facturation.chapon_prix_unitaire_ht.toFixed(2)} € / chapon`)}
                                            {display_row("Total H.T chapons", `${bon_facturation.chapon_prix_total_ht.toFixed(2)} €`)}
                                            {display_row("Total TVA chapons", `${bon_facturation.chapon_total_tva.toFixed(2)} €`)}
                                            {bon_facturation.antibio_prix_total_ht &&
                                                display_row("Total H.T désinfectant", `${bon_facturation.antibio_prix_total_ht.toFixed(2)} €`)}
                                            {bon_facturation.antibio_total_tva &&
                                                display_row(
                                                    `Total TVA désinfectant (${bon_facturation.antibio_taux_tva * 100} %)`,
                                                    `${bon_facturation.antibio_total_tva.toFixed(2)} €`
                                                )}
                                            {bon_facturation.prix_ttc && display_row("Total T.T.C", `${bon_facturation.prix_ttc.toFixed(2)} €`)}
                                        </React.Fragment>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                    {commande?.prix_status === 2 && (
                        <Alert variant="outlined" severity="info" sx={{ my: 1.5 }}>
                            Le groupement paie, l'éleveur n'a rien à payer
                        </Alert>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => set_show_bon(false)}>Fermer</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
