import http from "./httpService";
import {get_api_url } from "../components/utils"


const apiEndpoint = get_api_url() + "/operations";

function operationUrl(id) {
	return `${apiEndpoint}/${id}/`;
}

export function getOperations() {
	return http.get(apiEndpoint);
}

export function getOperation(operationId) {
	return http.get(operationUrl(operationId));
}

export function get_operations_commande(commande_id) {
	return http.get(
		apiEndpoint +
			"/?commande__id=" +
			commande_id
	);
}


export function getOperationCommandeOperateur(commande_id, operateur_id) {
	return http.get(
		apiEndpoint +
			"/?commande__id=" +
			commande_id +
			"&operateur__id=" +
			operateur_id +
			"&ordering=commande__date"
	);
}

export function saveMultilpleOperations(operations, fiche) {
	return http.post(`${get_api_url()}/save-multiple-operations`, {
		"operations": operations,
		"fiche": fiche
	})
}

export function saveOperation(operation) {
	if (operation.id) {
		const body = { ...operation };
		return http.put(operationUrl(operation.id), body);
	}

	return http.post(apiEndpoint + "/", operation);
}

export function deleteOperation(operationId) {
	return http.delete(operationUrl(operationId));
}
