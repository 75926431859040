import React, { useState, useEffect } from "react";
import {
    Grid,
    Typography,
    Box,
    Tabs,
    Tab,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
    Card,
    CardContent,
    CardActions,
    Button,
    Table,
    Skeleton,
    Dialog,
    DialogContent,
    TextField,
    DialogActions,
    DialogContentText,
    DialogTitle,
    TableRow,
    AppBar,
    IconButton,
    List,
    ListItem,
    ListItemText,
    TableBody,
    Toolbar,
    Alert,
    TableContainer,
    Paper,
} from "@mui/material";
import { getRemarquesDeplacement, saveRemarque, deleteRemarque } from "../../services/remarqueChefService";
import { TextInput } from "../common/TextInput";
import { getCurrentUser } from "../../services/authService";
import { getCurrentPersonnel } from "../../services/personnelService";
import { getBon } from "../../services/bonFacturationService";
import { getFicheCommande } from "../../services/ficheChaponnageService";
import { send_sms } from "../../services/sendinblue_service";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";
import { toast } from "react-toastify";
import moment from "moment";
import { display_row } from "../utils";
import { useForm } from "react-hook-form";
import AccordionDeplacement from "./accordionDeplacement";
import { get_planned_personnels_for_personnel } from "../../services/plannedPersonnelService";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export function Homepage() {
    const [value, setValue] = useState(0);
    const [current_personnel, set_current_personnel] = useState(null);
    const [deplacement_a_venir, set_deplacement_a_venir] = useState(null);
    const [deplacement_passe, set_deplacement_passe] = useState(null);
    const [loading, set_loading] = useState(true);
    const [prepared_remarque, set_prepared_remarque] = useState();
    const [selected_remarque, set_selected_remarque] = useState(null);
    const navigate = useNavigate();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handle_delete_remarque = async () => {
        try {
            if (!selected_remarque) return;
            const res = await deleteRemarque(selected_remarque.id);
            if (res.status < 200 || res.status >= 400) throw new Error("deleteRemarque() returned an error status code");
            set_selected_remarque(null);
        } catch (e) {
            console.log(e);
            toast.error("Impossible de supprimer cette remarque");
        }
    };

    const {
        control,
        formState: { errors },
        reset,
        getValues,
    } = useForm();

    function prepare_create_remarque(props) {
        let tmp_remarque = {
            author: current_personnel.id,
            saison: current_personnel.saison.id,
        };
        if ("planned_item" in props) tmp_remarque.planned_item = props.planned_item;
        else if ("deplacement" in props) tmp_remarque.deplacement = props.deplacement;

        set_prepared_remarque(tmp_remarque);
    }

    async function create_remarque() {
        try {
            const values = getValues();
            prepared_remarque.texte = values.remarque;
            const res = await saveRemarque(prepared_remarque);
            if (res.status < 200 || res.status >= 400) throw new Error("saveRemarque() returned an error status");
            reset();
            set_prepared_remarque(null);
        } catch (e) {
            console.log(e);
            toast.error("Erreur dans l'enregistrement de la remarque");
        }
    }

    useEffect(() => {
        async function fecthDeplacements() {
            try {
                const user = getCurrentUser();

                const { data: current_personnel_found } = await getCurrentPersonnel(user.id);
                if (!current_personnel_found?.length) {
                    set_loading(false);
                    return;
                }
                set_current_personnel(current_personnel_found[0]);

                const { data: planned_personnels_found } = await get_planned_personnels_for_personnel(current_personnel_found[0].id, "true", "");
                let depla_a_venir = [],
                    depla_passe = [];

                planned_personnels_found.map((planned_personnel) => {
                    const diff_depart = moment().startOf("day").diff(moment(planned_personnel.deplacement.startDate).startOf("day"), "days"); // diff > 0 dans le passé
                    const diff_retour = moment().startOf("day").diff(moment(planned_personnel.deplacement.endDate).startOf("day"), "days");
                    planned_personnel.deplacement.poste = planned_personnel.poste;
                    // retour est dans le passé => tout le déplacement est terminé
                    if (diff_depart > 0 && diff_retour > 0) depla_passe.push(planned_personnel.deplacement);
                    // retour est dans le futur et depart dans le passé => le déplacement est en cours
                    else if (diff_depart >= 0 && diff_retour <= 0) {
                        depla_passe.push(planned_personnel.deplacement);
                        depla_a_venir.push(planned_personnel.deplacement);
                    }

                    // retour et depart dans le futur => deplacement pas commencé
                    else if (diff_depart < 0 && diff_retour < 0) {
                        depla_a_venir.push(planned_personnel.deplacement);
                    }
                });

                set_deplacement_a_venir(depla_a_venir);
                set_deplacement_passe(depla_passe);
            } catch (e) {
                console.log(e);
                toast.error("Impossible de récupérer les déplacements");
            }
            set_loading(false);
        }

        document.title = "Chapi - Accueil";

        const u = getCurrentUser();
        if (!u) {
            navigate("/login");
        }
        if (u.is_superuser) navigate("/homepage-admin");

        fecthDeplacements();
    }, []);

    return (
        <Box sx={{ mx: 0 }}>
            <Grid container spacing={1}>
                <Grid item md={12}>
                    <Typography variant="h4">Déplacements</Typography>
                </Grid>

                <Box sx={{ width: "100%", mt: 1.5 }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="fullWidth">
                            <Tab label="À venir" {...a11yProps(0)} />
                            <Tab label="Passé" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <Box sx={{ width: { xs: "125%", md: "100%" }, ml: { xs: -4, md: 0 } }}>
                            {loading && (
                                <Grid item md={12}>
                                    <Skeleton variant="rectangular" height={118} />
                                </Grid>
                            )}
                            {deplacement_a_venir &&
                                deplacement_a_venir.map((d) => {
                                    return (
                                        <AccordionDeplacement
                                            deplacement={d}
                                            current_personnel={current_personnel}
                                            prepare_create_remarque={prepare_create_remarque}
                                            future={true}
                                            set_selected_remarque={set_selected_remarque}
                                            show_unfinished_commande={false}
                                        />
                                    );
                                })}
                        </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {deplacement_passe &&
                            deplacement_passe.map((d) => {
                                return (
                                    <AccordionDeplacement
                                        deplacement={d}
                                        current_personnel={current_personnel}
                                        prepare_create_remarque={prepare_create_remarque}
                                        future={false}
                                        set_selected_remarque={set_selected_remarque}
                                        show_unfinished_commande={true}
                                    />
                                );
                            })}
                    </TabPanel>
                </Box>
            </Grid>

            <Dialog open={prepared_remarque} onClose={() => set_prepared_remarque(null)}>
                <DialogTitle>Remarque</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ mb: 2 }}>Faire une remarque sur l'item sélectionné</DialogContentText>
                    <form>
                        <TextInput name="remarque" fullWidth control={control} autoFocus label="Texte" />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => set_prepared_remarque(null)}>Annuler</Button>
                    <Button onClick={create_remarque}>Enregistrer</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={selected_remarque} onClose={() => set_selected_remarque(null)}>
                <DialogTitle>Supprimer la remarque</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ mb: 2 }}>Voulez-vous vraiment supprimer cette remarque ?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => set_selected_remarque(null)}>Annuler</Button>
                    <Button onClick={handle_delete_remarque}>Supprimer</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
