import React from "react";
import { FiberManualRecord } from "@mui/icons-material";
import { Box, Rating, Divider } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import {
	TextField,
	Checkbox,
	Switch,
	FormGroup,
	FormControlLabel,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from "@mui/material";

export function SelectInput({ control, name, label, children, ...props }) {
	const label_id = `${name}-label`;
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onChange, value }, fieldState: { error } }) => {

				return (
					<FormControl fullWidth>
						<TextField
							value={value}
							select
							onChange={onChange}
							label={label}
							InputLabelProps={{
								shrink: true,
							}}
							{...props}
						>
							{children.map((child) => (
								<MenuItem key={child.value} value={child.value}>
									{child.text}
								</MenuItem>
							))}
						</TextField>
					</FormControl>
				);
			}}
		/>
	);
}
