import React, { useState } from "react";
import auth from "../services/authService";
import { TextInput } from "./common/TextInput";
import { SendAndArchiveOutlined } from "@mui/icons-material";
import {
    Box,
    CircularProgress,
    Button,
    Grid,
    Card,
    CardContent,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    Skeleton,
    DialogActions,
    Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { get_root_url, get_api_create_url } from "./utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const apiEndpoint = get_api_create_url();

export default function LoginForm() {
    const [loading, setLoading] = useState(false);
    const [open_dialog, set_open_dialog] = useState(false);
    const navigate = useNavigate();

    if (auth.getCurrentUser()) {
        navigate("/");
    }

    React.useEffect(() => {
        document.title = "Chapi - Connexion";
    });

    const schema = yup
        .object({
            username: yup
                .string()
                .typeError("Un nom d'utilisateur est requis")
                .min(1, "Un nom d'utilisateur est requis")
                .required("Un nom d'utilisateur est requis"),
            password: yup
                .string()
                .typeError("Un mot de passe est requis")
                .min(1, "Un mot de passe est requis")
                .required("Un mot de passe est requis"),
        })
        .required();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const { control: control_reset, handleSubmit: handle_reset } = useForm();

    const onSubmit = async (values) => {
        toast.info("Connexion ...");
        setLoading(true);
        try {
            const res = await axios.post(apiEndpoint, {
                username: values.username.toLowerCase(),
                password: values.password,
            });
            const jwt = res.data;

            localStorage.setItem("token", jwt.access);
            localStorage.setItem("refresh", jwt.refresh);

            toast.success("Connexion réussie !");
            window.location = "/";
        } catch (ex) {
            console.log(ex);
            toast.error("Mauvais nom d'utilisateur ou mot de passe");
        }
        setLoading(false);
    };
    const onSubmitReset = async (values) => {
        set_open_dialog(false);
        toast.info("Envoi de l'email de réinitialisation ...");
        try {
            const res = await axios.post(`${get_root_url()}/auth/users/reset_password/`, {
                email: values.email,
            });
            if (res.status >= 200 && res.status < 400) {
                toast.success("Email de réinitialisation envoyé !");
            } else throw new Error("reset_password returned an error status code");
        } catch (e) {
            console.log(e);
            toast.error("Erreur dans l'envoi du mail de réinitialisation, vérifiez votre adresse email");
        }
    };

    return loading ? (
        <Grid item md={12}>
            <Skeleton variant="rectangular" height={118} />
        </Grid>
    ) : (
        <Card>
            <CardContent>
                <Box marginBottom={2}>
                    <Typography variant="h4">Connexion</Typography>
                </Box>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} direction="column" alignItems="center" justify="center">
                        <Grid item xs={12} md={12}>
                            <TextInput
                                required
                                name="username"
                                autoFocus
                                fullWidth
                                control={control}
                                label="Nom d'utilisateur"
                                helperText={errors.username?.message}
                                error={Boolean(errors.username)}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextInput
                                required
                                name="password"
                                fullWidth
                                control={control}
                                type="password"
                                label="Mot de passe"
                                helperText={errors.password?.message}
                                error={Boolean(errors.password)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} paddingTop={5} direction="column" alignItems="center" justifyContent="center">
                        <Grid item xs={12}>
                            <Button onClick={() => set_open_dialog(true)}>Mot de passe oublié ?</Button>
                            <Button variant="contained" type="submit" endIcon={<SendAndArchiveOutlined />}>
                                Connexion
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </CardContent>

            <Dialog open={open_dialog} onClose={() => set_open_dialog(false)}>
                <form onSubmit={handle_reset(onSubmitReset)}>
                    <DialogTitle>Mot de passe oublié</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Veuillez renseigner l'adresse email de votre compte afin de recevoir un email pour réinitialiser votre mot de passe.
                        </DialogContentText>
                        <br />
                        <TextInput
                            required
                            name="email"
                            fullWidth
                            control={control_reset}
                            type="email"
                            label="Adresse email"
                            helperText={errors.email?.message}
                            error={Boolean(errors.email)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => set_open_dialog(false)}>Annuler</Button>
                        <Button type="submit">Envoyer un email de réinitialisation</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Card>
    );
}
