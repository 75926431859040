import React from "react";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import TimePicker from "@mui/lab/TimePicker";

export function TimePickerInput({ control, name, label, ...props }) {
	return (
		<Controller
			name={name}
			control={control}
			defaultValue={null}
			render={({ field: { onChange, value }, fieldState: { error } }) => {

				return (
					<TimePicker
						label={label}
						// inputFormat="DD/MM/yyyy"
						value={value}
						onChange={onChange}
						renderInput={(params) => (
							<TextField {...params} {...props} />
						)}
					/>
				);
			}}
		/>
	);
}
