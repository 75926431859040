import http from "./httpService";
import {get_api_url } from "../components/utils"


const apiEndpoint = get_api_url() + "/horaires";

function horaireUrl(id) {
    return `${apiEndpoint}/${id}/`;
}

export function getHoraires(year = null) {
    return http.get(`${apiEndpoint}/?ordering=debut&personnel__saison__year=${year}`);
}

export function getHoraire(HoraireId) {
    return http.get(horaireUrl(HoraireId));
}


export function saveMultilpleHoraires(horaires) {
	return http.post(`${get_api_url()}/save-multiple-horaires`, {
		"horaires": horaires
	})
}

export function getHorairesFiche(fiche_horaire_id) {
    return http.get(`${apiEndpoint}/?ordering=debut&fiche_horaire__id=${fiche_horaire_id}`)
}

export function getHorairesCommande(commande_id) {
    return http.get(`${apiEndpoint}/?ordering=debut&commande__id=${commande_id}`)
}

export function getHorairesPersonnel(personnelId, saison, month = null) {
    // let startDate, endDate;
    // if (!month) {
    //     startDate = `${saison}-01-01`;
    //     endDate = `${saison}-12-31`;
    // } else {
    //     endDate = `${saison}-${month}-${daysInMonth(month, saison)}`;
    //     startDate = `${saison}-${month}-01`;
    // }
    return http.get(
        `${apiEndpoint}/?personnel__id=${personnelId}&ordering=debut`
    );
}

export function saveHoraire(horaire) {
    if (horaire.id) {
        const body = { ...horaire };
        return http.put(horaireUrl(horaire.id), body);
    }

    return http.post(apiEndpoint + "/", horaire);
}

export function getCamionAmende(camion_id, date, saison_year) {
	return http.get(`${apiEndpoint}/?personnel__saison__year=${saison_year}&camion__id=${camion_id}&debut__lte=${date}&fin__gte=${date}&as_conducteur=true`);
}

export function deleteHoraire(horaireId) {
    return http.delete(horaireUrl(horaireId));
}
