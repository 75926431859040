import React, { useState, useEffect } from "react";
import { Menu, MenuItem, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { Create } from "@mui/icons-material";
import { mark_as_done, displayWithIcon } from "../utils";
import { getHorairesCommande } from "../../services/horaireService";

export function FicheHoraireStatus({ commande, restricted }) {
    const [fiche_horaire_id, set_fiche_horaire_id] = useState(null);
    const [done, set_done] = useState(commande?.fiche_horaire_done);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        async function fetch_horaires() {
            try {
                const { data: horaires_found } = await getHorairesCommande(commande.id);
                if (horaires_found?.length > 0) set_fiche_horaire_id(horaires_found[0].fiche_horaire.id);
            } catch (e) {
                console.log(e);
            }
        }
        if (commande?.id) fetch_horaires();
    }, [commande]);

    const set_fiche_horaire_done = (value) => {
        set_done(value);
        mark_as_done("fiche_horaire", commande, value);
        commande.fiche_horaire_done = value;
        handleClose();
    };

    const fiche_done = () => {
        return done || fiche_horaire_id;
    };

    return !commande?.id ? (
        <div></div>
    ) : (
        <div>
            <Button onClick={handleClick} endIcon={fiche_done() ? displayWithIcon(1) : <Create />} variant="outlined">
                Fiche horaire
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {!fiche_done() && (
                    <React.Fragment>
                        <MenuItem onClick={handleClose} component={Link} to={`/fiche-horaire/edit/?ids_commande=${commande?.id}`}>
                            Faire la fiche horaire
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                set_fiche_horaire_done(true);
                            }}
                        >
                            Marquer comme faite
                        </MenuItem>
                    </React.Fragment>
                )}

                {commande.fiche_horaire_done && (
                    <MenuItem
                        onClick={() => {
                            set_fiche_horaire_done(false);
                        }}
                    >
                        Marquer comme non faite
                    </MenuItem>
                )}

                {!restricted && fiche_done() && fiche_horaire_id && (
                    <React.Fragment>
                        <MenuItem onClick={handleClose} component={Link} to={`/horaires/fiche/${fiche_horaire_id}`}>
                            Voir la fiche horaire
                        </MenuItem>
                    </React.Fragment>
                )}
            </Menu>
        </div>
    );
}
