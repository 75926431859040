import http from "./httpService";
import {get_api_url } from "../components/utils"


const apiEndpoint = get_api_url() + "/images-album";

function albumUrl(id) {
    return `${apiEndpoint}/${id}/`;
}

export function getAlbums() {
    return http.get(`${apiEndpoint}/`);
}

export function getAlbum(albumId) {
    return http.get(albumUrl(albumId));
}

export function saveAlbum(album) {
    if (album.id) {
        const body = { ...album };
        return http.put(`${albumUrl(album.id)}`, body);
    }

    return http.post(`${apiEndpoint}/`, album);
}

export function deleteAlbum(albumId) {
    return http.delete(albumUrl(albumId));
}
