import React, { useState, useEffect } from "react";
import {
    Grid,
    Typography,
    Paper,
    Fab,
    Box,
    Button,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Table,
    TableBody,
    TableContainer,
} from "@mui/material";
import { ExpandMore, ArrowUpward } from "@mui/icons-material";
import DisplayFicheChaponnage from "./displayFicheChaponnage";
import DisplayHoraires from "./displayHoraires";
import OperationTableau from "../personnels/operationsTable";
import { display_row, format_tel_number_link, displayWithIcon } from "../utils";
import { Link } from "react-router-dom";
import moment from "moment";
import { get_deplacement_planned_personnels } from "../../services/plannedPersonnelService";
import { get_deplacement_planned_camions } from "../../services/plannedCamionService";
import { get_deplacement_planned_items } from "../../services/plannedItemService";

const items = [
    { label: "Volaille", key: "volaille" },
    { label: "Quantité", key: "quantite" },
    { label: "Type de mise à jeun", key: "mise_a_jeun" },
];

export default function AccordionDeplacement({ deplacement, current_date }) {
    const [accordion_open, set_accordion_open] = useState(false);
    const [chefs, set_chefs] = useState([]);
    const [planned_personnels, set_planned_personnels] = useState([]);
    const [planned_camions, set_planned_camions] = useState([]);
    const [planned_camping, set_planned_camping] = useState(null);
    const [planned_commandes_of_day, set_planned_commandes_of_day] = useState([]);

    useEffect(() => {
        async function fetch_planned_data() {
            try {
                const { data: perso_found } = await get_deplacement_planned_personnels(deplacement.id);
                set_planned_personnels(perso_found);
                set_chefs(perso_found.filter((item) => item.poste === "chef"));
                const { data: camions_found } = await get_deplacement_planned_camions(deplacement.id);
                set_planned_camions(camions_found);
                const { data: planned_items_found } = await get_deplacement_planned_items(deplacement.id);

                set_planned_camping(
                    planned_items_found.filter(
                        (item) =>
                            item?.camping?.id &&
                            moment(item.startDate).startOf("day").diff(moment(current_date).startOf("day")) <= 0 &&
                            moment(item.endDate).startOf("day").diff(moment(current_date).startOf("day")) >= 0
                    )[0]
                );

                set_planned_commandes_of_day(
                    planned_items_found?.filter(
                        (item) => moment(item.startDate).startOf("day").diff(current_date.startOf("day")) === 0 && item.commande !== null
                    )
                );
            } catch (e) {
                console.log(e);
            }
        }

        if (deplacement?.id) fetch_planned_data();
    }, [deplacement]);

    return (
        <Grid item md={12}>
            <Accordion sx={{ mb: 2 }} expanded={accordion_open}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => set_accordion_open(!accordion_open)}
                >
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4">
                                <span
                                    style={{
                                        color: deplacement?.equipe?.code_couleur ? deplacement.equipe.code_couleur : "black",
                                        backgroundColor: deplacement?.equipe?.code_couleur ? deplacement.equipe.code_couleur : "black",
                                        width: 40,
                                        marginRight: 10,
                                    }}
                                >
                                    ....
                                </span>
                                Équipe {deplacement?.equipe?.lettre ? deplacement.equipe.lettre : "??"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container justifyContent={"flex-end"}>
                                {chefs.map((c) => (c ? `${c.personnel.displayableName} ` : ""))}
                                {deplacement?.zones?.length > 0 && ` - ${JSON.parse(deplacement?.zones).map((z) => ` ${z}`)}`}
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" sx={{ my: 2 }}>
                                <Link to={`/deplacement/edit/${deplacement?.id}`}>
                                    Déplacement n°{deplacement?.numero_deplacement} du{" "}
                                    {moment(deplacement?.startDate).format("ddd DD/MM/YYYYY à HH:mm")} au{" "}
                                    {moment(deplacement?.endDate).format("ddd DD/MM/YYYY")}
                                </Link>
                            </Typography>

                            <TableContainer component={Paper} sx={{ maxWidth: 900 }}>
                                <Table sx={{ maxWidth: 900 }} aria-label="simple table" size="small">
                                    <TableBody>
                                        {planned_camions?.length > 0 &&
                                            display_row(
                                                "Camions",
                                                <React.Fragment>
                                                    {planned_camions.map((planned_camion) => (
                                                        <Typography>C{planned_camion.camion.numero}</Typography>
                                                    ))}
                                                </React.Fragment>
                                            )}
                                        {planned_personnels.filter((item) => item?.poste === "chef")?.length > 0 &&
                                            display_row(
                                                "Chef",
                                                <React.Fragment>
                                                    {planned_personnels
                                                        .filter((item) => item?.poste === "chef")
                                                        .map((planned_chef) => (
                                                            <Typography>{planned_chef.personnel.displayableName}</Typography>
                                                        ))}
                                                </React.Fragment>
                                            )}
                                        {planned_personnels.filter((item) => item?.poste === "operateur")?.length > 0 &&
                                            display_row(
                                                "Opérateur",
                                                <React.Fragment>
                                                    {planned_personnels
                                                        .filter((item) => item?.poste === "operateur")
                                                        .map((planned_chef) => (
                                                            <Typography>{planned_chef.personnel.displayableName}</Typography>
                                                        ))}
                                                </React.Fragment>
                                            )}
                                        {planned_personnels.filter((item) => item?.poste === "poseur")?.length > 0 &&
                                            display_row(
                                                "Poseur",
                                                <React.Fragment>
                                                    {planned_personnels
                                                        .filter((item) => item?.poste === "poseur")
                                                        .map((planned_chef) => (
                                                            <Typography>{planned_chef.personnel.displayableName}</Typography>
                                                        ))}
                                                </React.Fragment>
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {planned_camping && (
                                <React.Fragment>
                                    <Typography variant="h5">{`Camping`}</Typography>

                                    <TableContainer component={Paper} sx={{ maxWidth: 900 }}>
                                        <Table sx={{ maxWidth: 900 }} aria-label="simple table" size="small">
                                            <TableBody>
                                                {display_row(
                                                    "Nom",
                                                    <a href={`/campings/view/${planned_camping.camping?.id}`}>{planned_camping.camping?.nom}</a>
                                                )}
                                                {display_row("Adresse", planned_camping.camping.adresse)}
                                                {planned_camping.camping.observations &&
                                                    display_row("Observations", planned_camping.camping.observations)}
                                                {display_row("Téléphone", format_tel_number_link(planned_camping.camping.telephone))}
                                                {planned_camping.camping.remarque_planning &&
                                                    display_row("Remarque planning", planned_camping.camping?.remarque_planning)}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </React.Fragment>
                            )}
                        </Grid>
                    </Grid>

                    {planned_commandes_of_day?.length > 0 &&
                        planned_commandes_of_day.map((planned_commande) => (
                            <React.Fragment>
                                <Accordion TransitionProps={{ unmountOnExit: true }} sx={{ my: 3 }}>
                                    <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} md={10}>
                                                <Typography variant="h5">
                                                    Commande n°{planned_commande.commande?.numero_commande} - {planned_commande.commande?.client?.nom}{" "}
                                                    ({planned_commande.commande?.client?.groupement?.nom})
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <TableContainer component={Paper} sx={{ maxWidth: 900 }}>
                                                    <Table sx={{ maxWidth: 900 }} aria-label="simple table" size="small">
                                                        <TableBody>
                                                            {display_row(
                                                                "Client",
                                                                <Link to={`/clients/view/${planned_commande.commande?.client?.id}`}>
                                                                    {planned_commande.commande?.client?.nom}
                                                                </Link>
                                                            )}
                                                            {display_row("Appel J-2", displayWithIcon(planned_commande.commande.appel_j_moins_2))}
                                                            {items.map((item) => display_row(item.label, planned_commande.commande[item.key]))}
                                                            {planned_commande.commande.observations &&
                                                                display_row("Observations", planned_commande.commande.observations)}
                                                            {planned_commande.commande.remarque_planning &&
                                                                display_row("Remarques planning", planned_commande.commande.remarque_planning)}
                                                            {planned_commande.commande.client?.telephone &&
                                                                display_row(
                                                                    "Téléphone",
                                                                    format_tel_number_link(planned_commande.commande.client?.telephone)
                                                                )}
                                                            {display_row(
                                                                "Portable",
                                                                format_tel_number_link(planned_commande.commande.client?.portable)
                                                            )}
                                                            {display_row(
                                                                "",
                                                                <Link to={`/commandes/view/${planned_commande.commande?.id}`}>
                                                                    <Button color="primary" variant="outlined" sx={{ marginLeft: 1 }}>
                                                                        Voir la commande
                                                                    </Button>
                                                                </Link>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <DisplayFicheChaponnage commande={planned_commande.commande} />
                                            </Grid>

                                            <Grid item xs={12} md={12}>
                                                <DisplayHoraires commande_id={planned_commande.commande.id} />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </React.Fragment>
                        ))}
                </AccordionDetails>
                <Grid item xs={12} md={12} container justifyContent="flex-end" sx={{ my: 4 }}>
                    <Fab color="primary" aria-label="add" sx={{ mr: 1 }} onClick={() => set_accordion_open(false)}>
                        <ArrowUpward />
                    </Fab>
                </Grid>
            </Accordion>
        </Grid>
    );
}
