import axios from "axios";
import { get_api_refresh_url } from "../components/utils"
import { useNavigate } from "react-router-dom";


const axiosInstance = axios.create({
	headers: {
		"Content-Type": "application/json",
	},
});

function setJwt(jwt) {
	axiosInstance.defaults.headers.common["Authorization"] = "JWT " + jwt;
}

async function refreshToken() {
	const refresh_token = localStorage.getItem("refresh");
	if (!refresh_token) {
		window.location = "/logout";
	}
	const response = await axiosInstance.post(get_api_refresh_url(), {
		refresh: refresh_token,
	});
	axiosInstance.defaults.headers.common["Authorization"] =
		"JWT " + response.data.access;
	localStorage.setItem("token", response.data.access);
	return response.data.access;
}

axiosInstance.interceptors.request.use(
	async function (config) {
		const token = localStorage.getItem("token");
		if (token) {
			config.headers.Authorization = `JWT ${token}`;
		} else if (!config.url.includes("api/token")) {
			let token_refreshed = await refreshToken();
			config.headers.Authorization = `JWT ${token_refreshed}`;
		}
		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

axiosInstance.interceptors.response.use(
	function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response;
	},
	async function (error) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		try {

			const originalRequest = error.config;
			if (
				error?.response?.status === 401 &&
				originalRequest.url.includes("auth/jwt/refresh")
			) {
				window.location = "/logout"
				return Promise.reject(error);
			} else if (error.response.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true;
				await refreshToken();
				return axiosInstance(originalRequest);
			}
			return Promise.reject(error);
		} catch(e) {
				window.location = "/logout"
		}
	}
);

export default {
	get: axiosInstance.get,
	post: axiosInstance.post,
	put: axiosInstance.put,
	delete: axiosInstance.delete,
	setJwt,
};
