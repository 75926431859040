import React, { useState, useEffect, lazy } from "react";
import Paper from "@mui/material/Paper";
import { Typography, Button } from "@mui/material";
import {
    Scheduler,
    WeekView,
    Toolbar,
    DateNavigator,
    AllDayPanel,
    DayView,
    MonthView,
    AppointmentTooltip,
    LocaleSwitcher,
    AppointmentForm,
    Appointments,
    GroupingPanel,
    ConfirmationDialog,
    DragDropProvider,
    TodayButton,
    Resources,
} from "@devexpress/dx-react-scheduler-material-ui";
import { ViewState, EditingState, IntegratedEditing, GroupingState, IntegratedGrouping } from "@devexpress/dx-react-scheduler";

// import { get_planned_commandes, save_planned_commande, delete_planned_commande } from '../services/plannedCommandeService';
import { getEquipesCurrentSaison } from "../services/equipeService";
import { getDeplacements } from "../services/deplacementService";
import moment from "moment";
import { TextInput } from "./common/TextInput";
import { SwitchInput } from "./common/SwitchInput";
import { AutocompleteInput } from "./common/AutocompleteInput";
import * as QueryString from "query-string";

import { SelectInput } from "./common/SelectInput";
import { DateTimePickerInput } from "./common/DateTimePickerInput";
import { DatePickerInput } from "./common/DatePickerInput";

const appointments = [
    {
        title: "Website Re-Design Plan",
        startDate: new Date(2018, 5, 25, 9, 35),
        endDate: new Date(2018, 5, 25, 11, 30),
        id: 0,
        location: "Room 1",
    },
    {
        title: "Book Flights to San Fran for Sales Trip",
        startDate: new Date(2018, 5, 25, 12, 11),
        endDate: new Date(2018, 5, 25, 13, 0),
        id: 1,
        location: "Room 1",
    },
    {
        title: "Install New Router in Dev Room",
        startDate: new Date(2018, 5, 25, 14, 30),
        endDate: new Date(2018, 5, 25, 15, 35),
        id: 2,
        location: "Room 2",
    },
    {
        title: "Approve Personal Computer Upgrade Plan",
        startDate: new Date(2018, 5, 26, 10, 0),
        endDate: new Date(2018, 5, 26, 11, 0),
        id: 3,
        location: "Room 2",
    },
    {
        title: "Final Budget Review",
        startDate: new Date(2018, 5, 26, 12, 0),
        endDate: new Date(2018, 5, 26, 13, 35),
        id: 4,
        location: "Room 2",
    },
    {
        title: "New Brochures",
        startDate: new Date(2018, 5, 26, 14, 30),
        endDate: new Date(2018, 5, 26, 15, 45),
        id: 5,
        location: "Room 2",
    },
    {
        title: "Install New Database",
        startDate: new Date(2018, 5, 27, 9, 45),
        endDate: new Date(2018, 5, 27, 11, 15),
        id: 6,
        location: "Room 1",
    },
    {
        title: "Approve New Online Marketing Strategy",
        startDate: new Date(2018, 5, 27, 12, 0),
        endDate: new Date(2018, 5, 27, 14, 0),
        id: 7,
        location: "Room 3",
    },
    {
        title: "Upgrade Personal Computers",
        startDate: new Date(2018, 5, 27, 15, 15),
        endDate: new Date(2018, 5, 27, 16, 30),
        id: 8,
        location: "Room 3",
    },
    {
        title: "Customer Workshop",
        startDate: new Date(2018, 5, 28, 11, 0),
        endDate: new Date(2018, 5, 28, 12, 0),
        id: 9,
        location: "Room 3",
    },
    {
        title: "Prepare 2015 Marketing Plan",
        startDate: new Date(2018, 5, 28, 11, 0),
        endDate: new Date(2018, 5, 28, 13, 30),
        id: 10,
        location: "Room 1",
    },
    {
        title: "Brochure Design Review",
        startDate: new Date(2018, 5, 28, 14, 0),
        endDate: new Date(2018, 5, 28, 15, 30),
        id: 11,
        location: "Room 2",
    },
    {
        title: "Create Icons for Website",
        startDate: new Date(2018, 5, 29, 10, 0),
        endDate: new Date(2018, 5, 29, 11, 30),
        id: 12,
        location: "Room 2",
    },
    {
        title: "Upgrade Server Hardware",
        startDate: new Date(2018, 5, 29, 14, 30),
        endDate: new Date(2018, 5, 29, 16, 0),
        id: 13,
        location: "Room 3",
    },
    {
        title: "Submit New Website Design",
        startDate: new Date(2018, 5, 29, 16, 30),
        endDate: new Date(2018, 5, 29, 18, 0),
        id: 14,
        location: "Room 3",
    },
    {
        title: "Launch New Website",
        startDate: new Date(2018, 5, 29, 12, 20),
        endDate: new Date(2018, 5, 29, 14, 0),
        id: 15,
        location: "Room 2",
    },
    {
        title: "Website Re-Design Plan",
        startDate: new Date(2018, 6, 2, 9, 30),
        endDate: new Date(2018, 6, 2, 15, 30),
        id: 16,
        location: "Room 1",
    },
    {
        title: "Book Flights to San Fran for Sales Trip",
        startDate: new Date(2018, 6, 2, 12, 0),
        endDate: new Date(2018, 6, 2, 13, 0),
        id: 17,
        location: "Room 3",
    },
    {
        title: "Install New Router in Dev Room",
        startDate: new Date(2018, 6, 2, 14, 30),
        endDate: new Date(2018, 6, 2, 17, 30),
        id: 18,
        location: "Room 2",
    },
    {
        title: "Approve Personal Computer Upgrade Plan",
        startDate: new Date(2018, 6, 2, 16, 0),
        endDate: new Date(2018, 6, 3, 9, 0),
        id: 19,
        location: "Room 2",
    },
    {
        title: "Final Budget Review",
        startDate: new Date(2018, 6, 3, 10, 15),
        endDate: new Date(2018, 6, 3, 13, 35),
        id: 20,
        location: "Room 1",
    },
    {
        title: "New Brochures",
        startDate: new Date(2018, 6, 3, 14, 30),
        endDate: new Date(2018, 6, 3, 15, 45),
        id: 21,
        location: "Room 3",
    },
    {
        title: "Install New Database",
        startDate: new Date(2018, 6, 3, 15, 45),
        endDate: new Date(2018, 6, 4, 12, 15),
        id: 22,
        location: "Room 3",
    },
    {
        title: "Approve New Online Marketing Strategy",
        startDate: new Date(2018, 6, 4, 12, 35),
        endDate: new Date(2018, 6, 4, 14, 15),
        id: 23,
        location: "Room 3",
    },
    {
        title: "Upgrade Personal Computers",
        startDate: new Date(2018, 6, 4, 15, 15),
        endDate: new Date(2018, 6, 4, 20, 30),
        id: 24,
        location: "Room 2",
    },
    {
        title: "Customer Workshop",
        startDate: new Date(2018, 6, 5, 6, 0),
        endDate: new Date(2018, 6, 5, 14, 20),
        id: 25,
        location: "Room 1",
    },
    {
        title: "Customer Workshop",
        startDate: new Date(2018, 6, 5, 14, 35),
        endDate: new Date(2018, 6, 5, 16, 20),
        id: 26,
        location: "Room 1",
    },
    {
        title: "Customer Workshop 2",
        startDate: new Date(2018, 6, 5, 10, 0),
        endDate: new Date(2018, 6, 5, 11, 20),
        id: 27,
        location: "Room 2",
    },
    {
        title: "Prepare 2015 Marketing Plan",
        startDate: new Date(2018, 6, 5, 20, 0),
        endDate: new Date(2018, 6, 6, 13, 30),
        id: 28,
        location: "Room 3",
    },
    {
        title: "Brochure Design Review",
        startDate: new Date(2018, 6, 6, 14, 10),
        endDate: new Date(2018, 6, 6, 15, 30),
        id: 29,
        location: "Room 3",
    },
    {
        title: "Create Icons for Website",
        startDate: new Date(2018, 6, 6, 10, 0),
        endDate: new Date(2018, 6, 7, 14, 30),
        id: 30,
        location: "Room 1",
    },
    {
        title: "Upgrade Server Hardware",
        startDate: new Date(2018, 6, 3, 9, 30),
        endDate: new Date(2018, 6, 3, 12, 25),
        id: 31,
        location: "Room 2",
    },
    {
        title: "Submit New Website Design",
        startDate: new Date(2018, 6, 3, 12, 30),
        endDate: new Date(2018, 6, 3, 18, 0),
        id: 32,
        location: "Room 2",
    },
    {
        title: "Launch New Website",
        startDate: new Date(2018, 6, 3, 12, 20),
        endDate: new Date(2018, 6, 3, 14, 10),
        id: 33,
        location: "Room 2",
    },
    {
        title: "Book Flights to San Fran for Sales Trip",
        startDate: new Date(2018, 5, 26, 0, 0),
        endDate: new Date(2018, 5, 27, 0, 0),
        id: 34,
        location: "Room 1",
    },
    {
        title: "Customer Workshop",
        startDate: new Date(2018, 5, 29, 10, 0),
        endDate: new Date(2018, 5, 30, 14, 30),
        id: 35,
        location: "Room 1",
    },
    {
        title: "Google AdWords Strategy",
        startDate: new Date(2018, 6, 3, 0, 0),
        endDate: new Date(2018, 6, 4, 10, 30),
        id: 36,
        location: "Room 3",
    },
    {
        title: "Rollout of New Website and Marketing Brochures",
        startDate: new Date(2018, 6, 5, 10, 0),
        endDate: new Date(2018, 6, 9, 14, 30),
        id: 37,
        location: "Room 3",
    },
    {
        title: "Update NDA Agreement",
        startDate: new Date(2018, 6, 1, 10, 0),
        endDate: new Date(2018, 6, 3, 14, 30),
        id: 38,
        location: "Room 2",
    },
    {
        title: "Customer Workshop",
        startDate: new Date(2018, 6, 1),
        endDate: new Date(2018, 6, 2),
        allDay: true,
        id: 39,
        location: "Room 1",
    },
];

const groupOrientation = (viewName) => {
    console.log(viewName);
    return viewName.split(" ")[0];
};

const Appointment = ({ children, style, data, ...restProps }) => {
    if (!data) return;
    return (
        <Appointments.Appointment
            {...restProps}
            data={{ ...data }}
            style={{
                ...style,
                backgroundColor: data.color,
                minHeight: 50,
            }}
        >
            {data.equipe && <Typography sx={{ mt: 1, ml: 1, color: "white", fontWeight: "bold" }}>Equipe {data.equipe}</Typography>}

            {children}
        </Appointments.Appointment>
    );
};

export default function Test() {
    const [editingFormVisible, setEditingFormVisible] = useState(false);
    const [view, set_view] = useState("work-week");
    const [datas, set_datas] = useState([]);
    const [equipes, set_equipes] = useState([]);
    const [current_date, set_current_date] = useState(moment("14-12-2022", "DD-MM-YYYY").format("YYYY-MM-DD"));

    const BasicLayout = ({ onFieldChange, appointmentData, ...restProps }) => {
        const onCustomFieldChange = (nextValue) => {
            onFieldChange({ customField: nextValue });
        };
        console.log(appointmentData);
        return (
            <div>
                <Button type="submit" onClick={() => setEditingFormVisible(false)}>
                    balbla
                </Button>
            </div>
        );
        return (
            <AppointmentForm.BasicLayout appointmentData={appointmentData} onFieldChange={onFieldChange} {...restProps}>
                <AppointmentForm.Label text="Custom Field" type="title" />
                <AppointmentForm.TextEditor value={appointmentData.customField} onValueChange={onCustomFieldChange} placeholder="Custom field" />

                <AppointmentForm.Label text="Custom Field bis" type="title" />
                <AppointmentForm.Select
                    // value={appointmentData.customFieldBis}
                    onValueChange={(nextValue) => onFieldChange({ customFieldBis: nextValue })}
                    placeholder="Custom field bis"
                    availableOptions={[
                        { id: 1, text: "toto" },
                        { id: 2, text: "tata" },
                        { id: -1, text: "-1" },
                    ]}
                    type="filledSelect"
                />
            </AppointmentForm.BasicLayout>
        );
    };
    useEffect(() => {
        async function fetch_planned_commandes() {
            try {
                let result = [];
                // const { data: planned_commandes_found } = await get_planned_commandes();
                // planned_commandes_found?.map(planned_commande => {
                //     result.push({
                //         id: planned_commande.id,
                //         startDate: moment(planned_commande.start).format("YYYY-MM-DDTHH:mm"),
                //         endDate: moment(planned_commande.end).format("YYYY-MM-DDTHH:mm"),
                //         title: `N°${planned_commande.commande.numero_commande} - ${planned_commande.commande.client.nom}`,
                //         equipe: planned_commande?.deplacement?.equipe ? planned_commande?.deplacement?.equipe.lettre : "??",
                //         color: planned_commande?.deplacement?.equipe?.code_couleur ? planned_commande?.deplacement?.equipe?.code_couleur : "#b8b8b8",
                //         equipe_id: planned_commande?.deplacement?.equipe?.id ? planned_commande?.deplacement?.equipe?.id : -1
                //     })
                // })

                const { data: equipes_found } = await getEquipesCurrentSaison();
                equipes_found.map((equipe) => {
                    equipe.text = `Equipe ${equipe.lettre}`;
                    equipe.color = equipe.code_couleur;
                });
                set_equipes(equipes_found);
                const { data: deplacements_found } = await getDeplacements();
                deplacements_found.map((deplacement) => {
                    result.push({
                        id: deplacement.id,
                        startDate: moment(deplacement.startDate).format("YYYY-MM-DDTHH:mm"),
                        endDate: moment(deplacement.endDate).format("YYYY-MM-DDTHH:mm"),
                        title: `Déplacement n°${deplacement.numero_deplacement}`,
                        equipe: null,
                        color: deplacement?.equipe?.code_couleur ? deplacement?.equipe?.code_couleur : "#b8b8b8",
                        equipe_id: deplacement?.equipe?.id ? deplacement?.equipe?.id : -1,
                    });
                });

                set_datas(result);
            } catch (e) {
                console.log(e);
            }
        }

        fetch_planned_commandes();
    }, []);

    const change_view = (e) => {
        set_view(e);
    };

    const change_current_date = (e) => {
        set_current_date(e);
    };

    const commit_changes = async ({ added, changed, deleted }) => {
        console.log("commit");
        if (added) {
            console.log("add");
            console.log(added);
        }
        if (changed) {
            console.log("changed");
            console.log(changed);
        }
        if (deleted !== undefined) {
            // await delete_planned_commande(deleted);
            set_datas(datas.filter((item) => item.id !== deleted));
            console.log("deleted");
            console.log(deleted);
        }
        return { appointments };
    };

    return equipes?.length > 0 ? (
        <Paper>
            <Scheduler data={datas} height={800} firstDayOfWeek={1} locale={"fr-FR"}>
                {/* <LocaleSwitcher
                    currentLocale={"fr-FR"}
                /> */}
                <ViewState currentDate={current_date} onCurrentDateChange={change_current_date} />

                <Toolbar />
                <DateNavigator />
                <TodayButton />
                <EditingState onCommitChanges={commit_changes} />
                <GroupingState
                    grouping={[
                        {
                            resourceName: "equipe_id",
                        },
                    ]}
                    groupByDate={() => true}
                    groupOrientation={() => "Vertical"}
                />
                <IntegratedEditing />
                {/* <WeekView
                    startDayHour={7}
                    endDayHour={17}
                    name="Horizontal Orientation"
                /> */}

                {/* <WeekView
                    startDayHour={7}
                    endDayHour={17}
                    name="Vertical Orientation"
                /> */}
                <ConfirmationDialog />
                <Appointments appointmentComponent={Appointment} />
                <AllDayPanel allDay={"Toute la journée"} height={100} allDayPanelMode={"allDay"} />

                <Resources
                    data={[
                        {
                            fieldName: "equipe_id",
                            title: "Equipe",
                            instances: equipes,
                        },
                    ]}
                    mainResourceName="equipe_id"
                />
                <IntegratedGrouping />
                <AppointmentTooltip showOpenButton showDeleteButton />
                <AppointmentForm
                    basicLayoutComponent={BasicLayout}
                    visible={editingFormVisible}
                    onVisibilityChange={() => {
                        console.log("change");
                        setEditingFormVisible(!editingFormVisible);
                    }}
                />
                <DragDropProvider
                // allowDrag={allowDrag}
                />

                <GroupingPanel />
            </Scheduler>
        </Paper>
    ) : (
        <div></div>
    );
}
