import React, {Suspense, lazy} from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DateAdapter from "@mui/lab/AdapterMoment";
import "moment/locale/fr";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import {get_version} from "./components/utils"


const palette_prod = {
    primary: {
        main: "#5ec278"
    },
    secondary: {
        main: "#00938b"
    }
}

const palette_dev = {
    primary: {
        main: "#EF4541"
    },
    secondary: {
        main: "#FF5954"
    }
}

let palette = palette_prod

if(get_version() === "dev")
    palette = palette_dev


const outerTheme = createTheme({
    palette: palette
});
moment.locale("fr");

ReactDOM.render(
    <ThemeProvider theme={outerTheme}>
        <ToastContainer position="bottom-right" autoClose={5000} />

        <BrowserRouter>
        <Suspense fallback={<div>Chargement...</div>}>

            <LocalizationProvider dateAdapter={DateAdapter}>

                <App />
            </LocalizationProvider>
            </Suspense>

        </BrowserRouter>
    </ThemeProvider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
