import http from "./httpService";
import { get_api_url } from "../components/utils"
import moment from "moment"
const apiEndpoint = get_api_url() + "/planned-personnels";


function planned_personnel_url(id) {
	return `${apiEndpoint}/${id}/`;
}



export function get_client_planned_personnels(id_client, year_saison) {
	return http.get(
		apiEndpoint + "/?ordering=startDate&personnel__client__id=" + id_client + "&personnel__saison__year=" + year_saison
	);
}

export function get_planned_personnels(id_deplacement) {
	return http.get(
		apiEndpoint + "/?ordering=startDate&deplacement__id=" + id_deplacement
	);
}

export function get_planned_chef(id_personnel) {
	return http.get(
		`${apiEndpoint}/?ordering=startDate&personnel__id=${id_personnel}&poste=chef`
	);
}


export function get_planned_chef_at_date(id_personnel, date) {
	return http.get(
		`${apiEndpoint}/?ordering=startDate&personnel__id=${id_personnel}&startDate__lte=${moment(date).format("YYYY-MM-DD")}&endDate__gte=${moment(date).format("YYYY-MM-DD")}&poste=chef`
	);
}

export function get_planned_personnels_at_date(id_deplacement, date) {
	return http.get(
		`${apiEndpoint}/?ordering=startDate&deplacement__id=${id_deplacement}&startDate__lte=${moment(date).format("YYYY-MM-DD")}&endDate__gte=${moment(date).format("YYYY-MM-DD")}`
	);
}



export function get_chefs_deplacement(id_deplacement) {
	return http.get(
		apiEndpoint + "/?ordering=startDate&poste=chef&deplacement__id=" + id_deplacement
	);
}


export function get_campings_planned_personnels(id_camping, id_deplacement = "") {
	return http.get(
		apiEndpoint + "/?ordering=startDate&camping__id=" + id_camping + "&deplacement__id=" + id_deplacement
	);
}


export function get_deplacement_planned_personnels(id_deplacement) {
	return http.get(
		apiEndpoint +
			"/?ordering=poste,startDate&deplacement__id=" +
			id_deplacement
	);
}

export function get_planned_personnels_for_personnel(id_personnel, visible = "", poste="") {
	return http.get(
		apiEndpoint +
			"/?ordering=poste,startDate&personnel__id=" +
			id_personnel + "&deplacement__visible=" + visible + "&poste=" + poste
	);
}


export async function get_planned_personnel_interval(id_personnel, startDate, endDate) {
	const { data: start_date_in_interval } = await http.get(`${apiEndpoint}/?ordering=startDate&personnel__id=${id_personnel}&startDate__lte=${moment(startDate).format("YYYY-MM-DDTHH:mm")}&endDate__gte=${moment(startDate).format("YYYY-MM-DDTHH:mm")}`)
	const { data: end_date_in_interval } = await http.get(`${apiEndpoint}/?ordering=startDate&personnel__id=${id_personnel}&startDate__lte=${moment(endDate).format("YYYY-MM-DDTHH:mm")}&endDate__gte=${moment(endDate).format("YYYY-MM-DDTHH:mm")}`)

	// Filter to remove double deplacement
	return [...new Map([...start_date_in_interval, ...end_date_in_interval].map(item => [item.deplacement?.id, item])).values()];
}

export function get_planned_personnel_saison(year) {
	return http.get(
		apiEndpoint + "/?ordering=startDate&personnel__saison__year=" + year
	);
}

export function get_planned_personnel(planned_personnel_id) {
	return http.get(planned_personnel_url(planned_personnel_id));
}

export function save_planned_personnel(planned_personnel) {
	if (planned_personnel.id) {
		const body = { ...planned_personnel };
		return http.put(planned_personnel_url(planned_personnel.id), body);
	}

	return http.post(`${apiEndpoint}/`, planned_personnel);
}

export function delete_planned_personnel(planned_personnel_id) {
	return http.delete(planned_personnel_url(planned_personnel_id));
}
