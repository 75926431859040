import React, { useEffect, useState } from "react";
import {
    Grid,
    Typography,
    Table,
    Box,
    TableRow,
    TableHead,
    ButtonGroup,
    TableBody,
    Alert,
    AlertTitle,
    TableContainer,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Paper,
    TableCell,
    AccordionDetails,
    Accordion,
    AccordionSummary,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getFicheCommande } from "../../services/ficheChaponnageService";

import { getHorairesPersonnel, saveHoraire, getHoraire, deleteHoraire } from "../../services/horaireService";
import OperationTableau from "../personnels/operationsTable";
import { ExpandMore } from "@mui/icons-material";
import moment from "moment";

import { getOperationCommandeOperateur } from "../../services/operationService";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import {
    nmb_labels,
    luminosite_labels,
    presence_eleveur_labels,
    homogeniete_labels,
    intervalle_labels,
    proprete_labels,
    duree_pre_maj_labels,
    maj_labels,
    litiere_labels,
    aliment_litiere_labels,
    litiere_types,
    litiere_statuts,
    volaille_types,
    abreuvement_types,
    displayWithIcon,
    displayWithTooltip,
    display_row,
    mise_a_jeun_types,
} from "../utils";
import { getAlbum } from "../../services/albumService";

export default function DisplayFicheChaponnage({ commande }) {
    const [images, set_images] = useState(null);
    const [fiche, set_fiche] = useState(null);
    const [operations, set_operations] = useState(null);

    useEffect(() => {
        async function fetch_images() {
            try {
                const res = await getAlbum(commande?.fiche_chaponnage?.images);
                if (res.status < 200 || res.status >= 400 || !res.data) throw new Error("getAlbum() returned an error");
                set_images(res.data?.images);
            } catch (e) {
                toast.error("Impossible de charger les photos");
            }
        }
        async function fetch_fiche() {
            try {
                const { data: fiches_found } = await getFicheCommande(commande?.id);
                set_fiche(fiches_found[0]);
            } catch (e) {
                console.log(e);
                toast.error("Impossible de récupérer la fiche chaponnage");
            }
        }
        async function fetch_operations() {
            try {
                const { data: operations_found } = await getOperationCommandeOperateur(commande?.id, "");
                set_operations(operations_found);
            } catch (e) {
                console.log(e);
                toast.error("Impossible de récupérer les opérations");
            }
        }

        if (commande?.fiche_chaponnage?.images) {
            fetch_images();
        }

        if (commande?.id) {
            fetch_fiche();
            fetch_operations();
        }
    }, [commande]);

    const eb_eb_in_na = () => {
        return (
            <React.Fragment>
                {displayWithIcon(commande?.ecretage)}
                {displayWithIcon(commande?.ebarbillonnage)}
                {displayWithIcon(commande?.injection)}
                {displayWithIcon(commande?.notre_antibio)}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {fiche && (
                <React.Fragment>
                    <Typography variant="h5" sx={{ my: 2 }}>
                        Fiche chaponnage
                    </Typography>
                    <TableContainer component={Paper} sx={{ maxWidth: 900 }}>
                        <Table sx={{ maxWidth: 900 }} aria-label="simple table" size="small">
                            <TableBody>
                                {fiche?.date_creation &&
                                    display_row("Créée le", moment(fiche.date_creation, "YYYY-MM-DDTHH:mm:00").format("ddd DD/MM/YYYY à HH:mm"))}
                                {display_row("Présence éleveur", presence_eleveur_labels[fiche?.presence_eleveur])}
                                {display_row("Souche", commande?.souche?.nom)}
                                {display_row("Couvoir", fiche?.couvoir)}
                                {display_row("Vaccinés contre E-coli", displayWithIcon(fiche?.vaccines_ecoli))}
                                {display_row("Âge", `${fiche?.age} jours`)}
                                {display_row("Volaille", commande.volaille)}
                                {display_row("Poids", `${fiche.poids}g`)}
                                {display_row("Ec / Eb / Inj / NA", eb_eb_in_na())}
                                {commande?.injection && display_row("Antibiotique", fiche?.antibiotique)}
                                {commande?.injection && display_row("Dosage", fiche?.antibiotique_dosage)}
                                {display_row("Injection d'analgésique", displayWithIcon(fiche?.injection_analgesique))}
                                {fiche?.injection_analgesique && display_row("Analgésique", fiche?.analgesique)}
                                {fiche?.injection_analgesique && display_row("Dosage", fiche?.analgesique_dosage)}
                                {display_row("Température au matin", `${fiche?.temp_matin}°C`)}
                                {fiche.temp_midi && display_row("Température au midi", `${fiche?.temp_midi}°C`)}
                                {fiche.temp_soir && display_row("Température au soir", `${fiche?.temp_soir}°C`)}
                                {display_row("Chauffage pendant la mise à jeun", displayWithIcon(fiche?.chauffage_pendant_maj))}
                                {display_row("Chauffage post chaponnage", displayWithIcon(fiche?.chauffage_post_chaponnage))}
                                {display_row("Type de litière", fiche?.litiere_type)}
                                {display_row("Statut de la litière", fiche?.litiere_statut)}
                                {display_row("État de la litière", litiere_labels[fiche?.litiere_etat])}
                                {display_row("Poussière", nmb_labels[fiche?.poussiere])}
                                {display_row("Ammoniac", nmb_labels[fiche?.ammoniac])}
                                {display_row("Ténébrions", nmb_labels[fiche?.tenebrion])}
                                {display_row("Poux", nmb_labels[fiche?.poux])}
                                {display_row("Puces", nmb_labels[fiche?.puces])}
                                {display_row("Luminosité", luminosite_labels[fiche?.luminosite])}
                                {display_row("Flashs lumineux", displayWithIcon(fiche?.programme_lumineux))}
                                {display_row("Type d'abreuvement", fiche?.abreuvement)}
                                {display_row("Coupure circuit d'eau", moment(fiche?.coupure_circuit_eau).format("DD/MM/YYYY à HH:mm"))}
                                {display_row("Antibiotique dans l'eau", displayWithIcon(fiche?.antibiotique_eau))}
                                {fiche?.antibiotique_eau && display_row("Antibiotique", fiche?.antibiotique_eau_nom)}
                                {fiche?.antibiotique_eau && display_row("Dosage", fiche?.antibiotique_eau_dosage)}
                                {fiche?.antibiotique_eau && display_row("Durée", fiche?.antibiotique_eau_duree)}
                                {display_row("Homogénéité", homogeniete_labels[fiche?.homogeneite])}
                                {display_row("Sacs aériens voilés", intervalle_labels[fiche?.sacs_aeriens_voiles])}
                                {display_row("Sacs aériens épais", intervalle_labels[fiche?.sacs_aeriens_epais])}
                                {display_row("Nervosité", nmb_labels[fiche?.nervosite])}
                                {display_row("Hémorragie", nmb_labels[fiche?.hemorragie])}
                                {display_row("Vitellus", intervalle_labels[fiche?.vitellus])}
                                {display_row("Propreté des animaux", proprete_labels[fiche?.proprete_animaux])}
                                {display_row("Propreté des pattes", proprete_labels[fiche?.proprete_pattes])}
                                {display_row("Plantules sales", intervalle_labels[fiche?.plantules_sales])}
                                {display_row("Plantules infectées", intervalle_labels[fiche?.plantules_infectees])}
                                {display_row("Durée de la mise à jeun à compter des mangeoires vides", `${fiche?.duree_maj}h`)}
                                {display_row("Qualité de la mise à jeun", maj_labels[fiche?.qualite_maj])}
                                {display_row("Remarques sur la mise à jeun", fiche?.remarques_maj)}
                                {display_row("Type de mise à jeun", commande.mise_a_jeun)}
                                {commande.mise_a_jeun === "PAILLE" && (
                                    <React.Fragment>
                                        {display_row("Durée de la pré mise à jeun", duree_pre_maj_labels[fiche?.duree_pre_maj])}
                                        {display_row("Remarques sur la pré mise à jeun", fiche?.remarque_pre_maj)}
                                        {display_row("Aliment dans la litière", aliment_litiere_labels[fiche?.aliment_litiere])}
                                    </React.Fragment>
                                )}
                                {commande.mise_a_jeun === "CAISSE" && (
                                    <React.Fragment>
                                        {display_row("Nombre d'animaux par caisse", fiche?.nombre_animaux_caisse)}
                                        {display_row("Propreté des caisses", proprete_labels[fiche?.proprete_caisse])}
                                    </React.Fragment>
                                )}
                                {commande.mise_a_jeun === "FILET" && (
                                    <React.Fragment>{display_row("Type de filet", fiche?.type_filet)}</React.Fragment>
                                )}
                                {display_row("Pinces utilisées", fiche?.pince?.nom)}
                                {display_row("Nombre d'animaux passés sur table", fiche?.passes_sur_table)}
                                {display_row("Nombre de femelles", fiche?.femelles)}
                                {display_row("Nombre de morts", fiche?.morts)}
                                {display_row("Nombre de chapons", fiche?.chapons)}

                                {display_row("Remarques éleveur", displayWithTooltip(fiche?.remarques_eleveur))}
                                {display_row("Remarques chef d'équipe", displayWithTooltip(fiche?.remarques_chef))}
                                {display_row(
                                    "Signature de l'éleveur",
                                    <img
                                        style={{ width: "70%", maxWidth: "70%", height: "70px" }}
                                        src={fiche?.signature_eleveur}
                                        alt="signature_eleveur"
                                    />
                                )}
                                {display_row(
                                    "Signature du chef d'équipe",
                                    <img style={{ width: "70%", maxWidth: "70%", height: "70px" }} src={fiche?.signature_chef} alt="signature_chef" />
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </React.Fragment>
            )}
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                {images && images?.length > 0 && (
                    <React.Fragment>
                        <Grid item xs={12} md={12}>
                            <Typography variant="h5">Images</Typography>
                        </Grid>

                        {images.map((image) => {
                            return (
                                <Grid item xs={12} md={12}>
                                    <img style={{ maxWidth: "100%" }} src={image.image} alt={image.name}></img>
                                </Grid>
                            );
                        })}
                    </React.Fragment>
                )}
            </Grid>
            {operations?.length > 0 && (
                <React.Fragment>
                    <Typography variant="h5" sx={{ my: 2 }}>
                        Opérations
                    </Typography>
                    <OperationTableau editable={true} personnel={false} commande={false} operations={operations} />
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
