import React, { useEffect, useState } from "react";
import {
    Grid,
    Typography,
    Table,
    Box,
    TableRow,
    TableHead,
    ButtonGroup,
    TableBody,
    Alert,
    AlertTitle,
    TableContainer,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Paper,
    TableCell,
    AccordionDetails,
    Accordion,
    AccordionSummary,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { getHorairesPersonnel, saveHoraire, getHoraire, deleteHoraire, getHorairesFiche, getHorairesCommande } from "../../services/horaireService";
import { getFicheHoraire } from "../../services/ficheHoraireService";

import { ExpandMore } from "@mui/icons-material";
import moment from "moment";

import { getOperationCommandeOperateur } from "../../services/operationService";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import HoraireTableau from "../horaires/horaireTable";
import FicheHoraireTableau from "../horaires/ficheHoraireTable";

export default function DisplayHoraires({ commande_id }) {
    const [fiches_horaire, set_fiches_horaire] = useState(null);

    useEffect(() => {
        async function fetchHoraires() {
            try {
                const { data: horairesFound } = await getHorairesCommande(commande_id);
                if (horairesFound?.length !== 0) {
                    const { data: fichesFound } = await getFicheHoraire(horairesFound[0].fiche_horaire.id);
                    set_fiches_horaire([fichesFound]);
                }
            } catch (e) {
                console.log(e);
                toast.error("Impossible de récupérer les horaires de la commande");
            }
        }

        if (commande_id) {
            fetchHoraires();
        }
    }, [commande_id]);

    return (
        <React.Fragment>
            {fiches_horaire && (
                <React.Fragment>
                    <Typography variant="h5" sx={{ my: 2 }}>
                        Fiches horaire
                    </Typography>

                    <FicheHoraireTableau horaires={fiches_horaire} param_height={200} />
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
