import http from "./httpService";
import { get_api_url } from "../components/utils"


const apiEndpoint = get_api_url() + "/tickets";


function ticket_url(id) {
	return `${apiEndpoint}/${id}/`;
}

export function get_tickets(saison_year = "") {
	return http.get(`${apiEndpoint}/?ordering=date&saison__year=${saison_year}`)
}

export function get_tickets_current_saison() {
	return http.get(`${apiEndpoint}/?ordering=date&saison__current_saison=true`);
}

export function get_ticket(ticket_id) {
	return http.get(ticket_url(ticket_id));
}

export function save_ticket(ticket) {
	if (ticket.id) {
		return http.put(ticket_url(ticket.id), { ...ticket });
	}
	delete ticket.id
	return http.post(`${apiEndpoint}/`, ticket);
}

export function delete_ticket(ticket_id) {
	return http.delete(ticket_url(ticket_id));
}
