import React, { useState } from "react";
import {
    Grid,
    Divider,
    Box,
    Fab,
    Stack,
    Dialog,
    DialogContent,
    DialogContentText,
    Backdrop,
    CircularProgress,
    Alert,
    DialogTitle,
    Button,
    Typography,
    AlertTitle,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from "@mui/material";
import { useFieldArray } from "react-hook-form";
import { AutocompleteInput } from "../common/AutocompleteInput";
import { TimePickerInput } from "../common/TimePickerInput";
import { DateTimePickerInput } from "../common/DateTimePickerInput";
import { PersonAdd, PersonRemove } from "@mui/icons-material";
import { DataGridPro, frFR, GridToolbar } from "@mui/x-data-grid-pro";
import { Link } from "react-router-dom";
import moment from "moment";
import { ExpandMore } from "@mui/icons-material";
import { on_state_change, get_density } from "../../services/uxService";

import { displayField, displayWithIcon, displayWithTooltip, formatDuration } from "../utils";
import { getHorairesPersonnel, saveHoraire, getHoraire, deleteHoraire } from "../../services/horaireService";
import { deleteFicheHoraire, getFicheHoraire, saveFicheHoraire } from "../../services/ficheHoraireService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function FicheHoraireTableau({ horaires, editable, personnel, commande, restricted, param_height }) {
    const navigate = useNavigate();
    let horairesColumns = [
        {
            field: "id",
            headerName: "ID",
            headerAlign: "center",
            align: "center",
            width: 70,
        },
        {
            field: "date",
            headerName: "Date",
            headerAlign: "center",
            align: "center",
            type: "date",
            width: 120,
            renderCell: ({ value }) => (value ? moment(value).format("ddd DD/MM/YYYY") : ""),
        },
        {
            field: "creator",
            headerName: "Créateur",
            headerAlign: "center",
            width: 120,
            renderCell: ({ value }) => (value ? value.displayableName : ""),
        },
        {
            field: "equipe",
            headerName: "Equipe",
            headerAlign: "center",
            align: "center",
            width: 70,
            valueGetter: ({ value }) => (value ? value.lettre : ""),
        },
        {
            field: "type",
            headerName: "Type",
            headerAlign: "center",
            align: "center",
            width: 90,
        },
        {
            field: "debut_chantier",
            headerName: "Début",
            headerAlign: "center",
            align: "center",
            type: "dateTime",
            width: 130,
            renderCell: ({ value }) => (value ? moment(value).format("DD/MM/YYYY HH:mm") : ""),
        },
        {
            field: "pause",
            headerName: "Pause",
            headerAlign: "center",
            align: "center",
            width: 100,
        },
        {
            field: "fin_chantier",
            headerName: "Fin",
            headerAlign: "center",
            align: "center",
            width: 130,
            type: "dateTime",
            renderCell: ({ value }) => (value ? moment(value).format("DD/MM/YYYY HH:mm") : ""),
        },
        {
            field: "temps_route",
            headerName: "Route",
            headerAlign: "center",
            align: "center",
            width: 100,
        },
        {
            field: "nb_clients",
            headerName: "Nombre clients",
            headerAlign: "center",
            type: "number",
            align: "center",
            width: 150,
        },
        {
            field: "vu",
            headerName: "Vu",
            headerAlign: "center",
            align: "center",
            type: "boolean",
            editable: true,
            width: 50,
            renderCell: ({ value }) => displayWithIcon(value),
        },
        {
            field: "images",
            headerName: "Images",
            headerAlign: "center",
            align: "center",
            type: "boolean",
            width: 80,
            renderCell: ({ value }) => displayWithIcon(value),
        },
        {
            field: "remarque",
            headerName: "Remarque",
            headerAlign: "center",
            editable: true,
            width: 350,
            renderCell: ({ value }) => displayWithTooltip(value),
        },
    ];

    const [openDialog, setOpenDialog] = useState(false);
    const [selected_fiche_horaire, set_selected_fiche_horaire] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleCellEditCommitHoraires = async (params) => {
        try {
            const { data: fiche } = await getFicheHoraire(params.id);
            fiche[params.field] = params.value;

            if (params.row) params.row[params.field] = params.value;
            delete fiche.creator;
            delete fiche.equipe;
            const res = await saveFicheHoraire(fiche);
            if (res.status < 200 || res.status >= 400) throw new Error("saveFicheHoraire() returned an error status code");
        } catch (e) {
            console.log(e);
            toast.error("Erreur dans la sauvegarde de la fiche horaire");
        }
    };

    const onDeleteFicheHoraire = async (fiche_id) => {
        setOpenDialog(false);
        setLoading(true);
        toast.info("Suppression en cours ...");
        try {
            await deleteFicheHoraire(fiche_id);
        } catch (e) {
            console.log(e);
            toast.error("Impossible de supprimer un ou plusieurs horaires de la fiche");
            return;
        }
        setLoading(false);
        navigate("/horaires/saison");
    };

    const onHoraireRowClick = (e) => {
        if (e.field !== "id" || restricted) return;
        // navigate(`/horaires/fiche/${e.row.id}`)
        set_selected_fiche_horaire(e.row);
        setOpenDialog(true);
    };

    const height = param_height ? param_height : 900;

    return (
        <React.Fragment>
            <Backdrop
                open={loading}
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <div style={{ height: height, width: "100%" }}>
                <DataGridPro
                    density={get_density()}
                    onStateChange={on_state_change}
                    components={{ Toolbar: GridToolbar }}
                    onCellEditCommit={handleCellEditCommitHoraires}
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    disableColumnFilter
                    rows={horaires}
                    columns={horairesColumns}
                    onCellClick={(params, event) => {
                        if (!event.ctrlKey) {
                            event.defaultMuiPrevented = true;
                            onHoraireRowClick(params);
                        }
                        return;
                    }}
                />
            </div>

            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                maxWidth="md"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Détails</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid
                            container
                            spacing={2}
                            // direction="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ my: 1 }}
                        >
                            <Grid item xs={12} md={12} align="center">
                                Fiche n°{selected_fiche_horaire?.id}
                            </Grid>

                            <Grid item xs={12} md={12} align="center">
                                <Link to={`/horaires/fiche/${selected_fiche_horaire?.id}`}>
                                    <Button variant="contained" color="success">
                                        Voir les horaires liés à cette fiche horaire
                                    </Button>
                                </Link>
                            </Grid>
                            <Grid item xs={12} md={12} />
                            <Grid item md={12}>
                                <Accordion sx={{ mb: 2 }}>
                                    <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Typography>Suppressions</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Alert severity="warning" sx={{ my: 2 }}>
                                            <AlertTitle>Attention</AlertTitle>
                                            Attention ! Les actions de suppression <strong>ne sont pas réversibles !</strong>
                                        </Alert>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            sx={{ mr: 3 }}
                                            onClick={async () => await onDeleteFicheHoraire(selected_fiche_horaire?.id)}
                                        >
                                            Supprimer cette fiche horaire et les horaires associés
                                        </Button>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
