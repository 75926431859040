import http from "./httpService";
import {get_api_url } from "../components/utils"


const apiEndpoint = get_api_url() + "/remarques-chef";

function remarqueUrl(id) {
    return `${apiEndpoint}/${id}/`;
}

export function getRemarques(saison_year = "") {
    return http.get(`${apiEndpoint}/?ordering=date&saison__year=${saison_year}`);
}

export function getRemarquesDeplacement(personnel_id, deplacement_id) {
    return http.get(`${apiEndpoint}/?ordering=date&author__id=${personnel_id}&deplacement__id=${deplacement_id}`);
}


export function getRemarquesPlannedItem(personnel_id, planned_item_id) {
    return http.get(`${apiEndpoint}/?ordering=date&author__id=${personnel_id}&planned_item__id=${planned_item_id}`);
}

export function getRemarque(remarqueId) {
    return http.get(remarqueUrl(remarqueId));
}

export function saveRemarque(remarque) {
    if (remarque.id) {
        const body = { ...remarque };
        return http.put(`${remarqueUrl(remarque.id)}`, body);
    }

    return http.post(`${apiEndpoint}/`, remarque);
}

export function deleteRemarque(remarqueId) {
    return http.delete(remarqueUrl(remarqueId));
}
