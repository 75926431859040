import http from "./httpService";
import {get_api_url } from "../components/utils"


const apiEndpoint = get_api_url() + "/parameters";

function parameterUrl(id) {
	return `${apiEndpoint}/${id}`;
}

export function getParameters() {
	return http.get(`${apiEndpoint}/?ordering=id`);
}

export function getParameter(ParameterId) {
	return http.get(parameterUrl(ParameterId));
}

export function saveParameter(parameter) {
	if (parameter.id) {
		const body = { ...parameter };
		let res;
		try {
			res = http.put(parameterUrl(parameter.id) + "/", body);
		} catch (error) {
			console.error(error.response.data); // NOTE - use "error.response.data` (not "error")
		}

		return res;
	}

	return http.post(apiEndpoint + "/", parameter);
}

export function deleteParameter(parameterId) {
	return http.delete(parameterUrl(parameterId));
}
