import React, { useState, useEffect } from "react";
import {
    Grid,
    Typography,
    Card,
    CardContent,
    CardActions,
    Button,
    Table,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    TableBody,
    Menu,
    MenuItem,
    CardHeader,
    IconButton,
    ListItemIcon,
    TableContainer,
    Paper,
} from "@mui/material";
import moment from "moment";
import { Navigation, MoreVert, SpeakerNotes, Check } from "@mui/icons-material";
import { format_tel_number_link, display_row, openInNewTab } from "../utils";
import { save_planned_item } from "../../services/plannedItemService";
import { toast } from "react-toastify";
import { getRemarquesPlannedItem } from "../../services/remarqueChefService";
import { FaWaze } from "react-icons/fa";
import { SiGooglemaps } from "react-icons/si";
import FindCamping from "./find_camping";

export function PlannedCampingCard({ planned_camping, create_remarque, current_personnel, set_selected_remarque }) {
    const [remarques, set_remarques] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [reservation_done, set_reservation_done] = useState(planned_camping?.reserve);
    const [points_cardinaux, set_points_cardinaux] = useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    async function update_reservation() {
        try {
            if (!planned_camping) throw new Error("Planned camping is null");
            const res = await save_planned_item({ id: planned_camping.id, reserve: !reservation_done });
            if (res.status < 200 || res.status >= 400) throw new Error("save_planned_item() returned an error status code");
            set_reservation_done(!reservation_done);
        } catch (e) {
            console.log(e);
            toast.error("Erreur dans la mise à jour de la réservation");
        }
    }

    useEffect(() => {
        async function get_remarques() {
            if (!current_personnel || !planned_camping) return;
            try {
                const res = await getRemarquesPlannedItem(current_personnel.id, planned_camping.id);
                if (res.status < 200 || res.status >= 400) throw new Error("Error from getRemarques");
                set_remarques(res.data);
            } catch (e) {
                console.log(e);
                toast.error("Impossible de récupérer les remarques");
            }
        }

        get_remarques();
    }, [planned_camping]);

    return (
        <Card sx={{ minWidth: 275, my: 2 }}>
            <CardHeader
                action={
                    <React.Fragment>
                        <IconButton aria-label="settings" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
                            <MoreVert />
                        </IconButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                        >
                            <MenuItem
                                onClick={() => {
                                    handleClose();
                                    create_remarque({ planned_item: planned_camping.id });
                                }}
                            >
                                Faire une remarque sur cette réservation
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleClose();
                                    set_points_cardinaux(planned_camping?.camping?.points_cardinaux);
                                }}
                            >
                                Trouver un camping proche
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleClose();
                                    openInNewTab(
                                        `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${planned_camping?.camping?.points_cardinaux}`
                                    );
                                }}
                            >
                                <SiGooglemaps />
                                Google Maps
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleClose();
                                    openInNewTab(
                                        `https://www.waze.com/ul?ll=${planned_camping?.camping?.points_cardinaux.replace(
                                            ",",
                                            "%2C"
                                        )}&navigate=yes&zoom=17`
                                    );
                                }}
                            >
                                <FaWaze />
                                Waze
                            </MenuItem>
                        </Menu>
                    </React.Fragment>
                }
                title={
                    <Typography
                        variant="h5"
                        component="div"
                        sx={{ backgroundColor: "#b7e1cd", backgroundSize: "contain", px: 0.5, py: 0.5, fontWeight: "bold" }}
                    >
                        {planned_camping.camping?.nom}
                    </Typography>
                }
            />
            <CardContent sx={{ mt: -4 }}>
                <Grid container spacing={1} sx={{ mb: 1 }}>
                    <Grid item xs={12} md={12}>
                        <Typography>Du {moment(planned_camping.startDate, "YYYY-MM-DDTHH:mm").format("ddd DD MMM")} (soir)</Typography>
                        <Typography>
                            au{" "}
                            {moment(planned_camping.endDate, "YYYY-MM-DDTHH:mm")
                                .add(
                                    moment(planned_camping.startDate, "YYYY-MM-DDTHH:mm")
                                        .startOf("day")
                                        .diff(moment(planned_camping.endDate, "YYYY-MM-DDTHH:mm").startOf("day"), "days") === 0
                                        ? 1
                                        : 0,
                                    "days"
                                )
                                .format("ddd DD MMM")}{" "}
                            (matin)
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        {!reservation_done ? (
                            <Button variant="outlined" size="small" color="secondary" onClick={update_reservation}>
                                Camping à réserver
                            </Button>
                        ) : (
                            <Button variant="outlined" size="small" startIcon={<Check />} onClick={update_reservation}>
                                Camping réservé
                            </Button>
                        )}
                    </Grid>
                </Grid>

                <TableContainer component={Paper} sx={{ maxWidth: 900 }}>
                    <Table sx={{ maxWidth: 900 }} aria-label="simple table" size="small">
                        <TableBody>
                            {display_row(
                                "Téléphones",
                                <React.Fragment>
                                    {format_tel_number_link(planned_camping?.camping?.telephone)}
                                    {planned_camping.camping.portable ? (
                                        <span> - {format_tel_number_link(planned_camping?.camping?.portable)} </span>
                                    ) : (
                                        ""
                                    )}
                                </React.Fragment>
                            )}
                            {display_row("Adresse", planned_camping.camping.adresse)}
                            {display_row("Temps de route", planned_camping.temps_route)}
                            {display_row("Points cardinaux", planned_camping?.camping?.points_cardinaux)}
                            {planned_camping?.remarque_planning && display_row("Remarque planning", planned_camping?.remarque_planning)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
            {remarques?.length > 0 && (
                <React.Fragment>
                    <ListItem>
                        <ListItemIcon>
                            <SpeakerNotes />
                        </ListItemIcon>
                        <span style={{ fontWeight: "bold" }}>Remarques</span>
                    </ListItem>
                    <List component="div" disablePadding sx={{ mb: 2 }}>
                        {remarques.map((r) => (
                            <span onClick={() => set_selected_remarque(r)}>
                                <ListItemButton>{r.texte}</ListItemButton>
                            </span>
                        ))}
                    </List>
                </React.Fragment>
            )}
            <FindCamping points_cardinaux={points_cardinaux} set_points_cardinaux={set_points_cardinaux} />
        </Card>
    );
}
