import React, { useState, useEffect } from "react";
import {
    Grid,
    Typography,
    Box,
    Tabs,
    Tab,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
    Card,
    CardContent,
    CardActions,
    Button,
    Table,
    CardHeader,
    Skeleton,
    Dialog,
    DialogContent,
    TextField,
    DialogActions,
    Badge,
    DialogContentText,
    DialogTitle,
    TableRow,
    AppBar,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    ListItemIcon,
    TableBody,
    Toolbar,
    Alert,
    TableContainer,
    Paper,
} from "@mui/material";
import { Close, LocalShipping, Public, SpeakerNotes, Send, Edit } from "@mui/icons-material";
import { getRemarquesDeplacement, saveRemarque } from "../../services/remarqueChefService";
import { TextInput } from "../common/TextInput";
import { GrCircleInformation } from "react-icons/gr";
import jwtDecode from "jwt-decode";
import { getJwt } from "../../services/authService";
import { getCurrentUser } from "../../services/authService";
import { getCurrentPersonnel } from "../../services/personnelService";
import { getBon } from "../../services/bonFacturationService";
import { getFicheCommande } from "../../services/ficheChaponnageService";
import { IoPersonCircleOutline } from "react-icons/io5";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import moment from "moment";
import { ExpandMore, RotateLeft, Navigation, Create } from "@mui/icons-material";
import { format_tel_number_link, displayWithIcon, display_row, display_equipe } from "../utils";
import { Link } from "react-router-dom";
import { CommandeCard } from "./commandeCard";
import { useForm, useWatch } from "react-hook-form";
import { PlannedCampingCard } from "./planned_camping_card";
import { FaSyringe } from "react-icons/fa";
import { get_deplacement_planned_personnels } from "../../services/plannedPersonnelService";
import { get_deplacement_planned_camions } from "../../services/plannedCamionService";
import { get_deplacement_planned_items } from "../../services/plannedItemService";

function DeplacementCard({ planned_formation }) {
    return (
        <Card sx={{ minWidth: 275, my: 2 }}>
            <CardHeader
                title={
                    <Typography
                        variant="h5"
                        component="div"
                        sx={{ backgroundColor: "#bbceed", backgroundSize: "contain", px: 0.5, py: 0.5, fontWeight: "bold" }}
                    >
                        Formation
                    </Typography>
                }
            />
            <CardContent sx={{ mt: -4 }}>
                <Grid container spacing={1} sx={{ mb: 1 }}>
                    <Grid item xs={12} md={12}>
                        <Typography>{moment(planned_formation.startDate, "YYYY-MM-DDTHH:mm").format("ddd DD MMM à HH:mm")}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default function AccordionDeplacement({
    deplacement,
    prepare_create_remarque,
    current_personnel,
    future,
    set_selected_remarque,
    show_unfinished_commande,
}) {
    const [accordion_open, setAccordion_open] = useState(false);
    const [remarques, set_remarques] = useState();
    const [message_to_all, set_message_to_all] = useState();
    const [planned_personnels, set_planned_personnels] = useState([]);
    const [planned_camions, set_planned_camions] = useState([]);
    const [planned_items, set_planned_items] = useState([]);
    const [is_chef, set_is_chef] = useState(false);
    const [commandes_not_finished, set_commandes_not_finished] = useState(0);

    useEffect(() => {
        async function fetch_deplacement_datas() {
            try {
                set_is_chef(deplacement.poste === "chef");
                const { data: planned_personnels_found } = await get_deplacement_planned_personnels(deplacement.id);
                set_planned_personnels(planned_personnels_found);
                const { data: planned_camions_found } = await get_deplacement_planned_camions(deplacement.id);
                set_planned_camions(planned_camions_found);
                const { data: planned_items_found } = await get_deplacement_planned_items(deplacement.id);
                set_planned_items(planned_items_found);
                let commandes_not_finished_tmp = 0;
                if (show_unfinished_commande) {
                    planned_items_found.map((planned_item) => {
                        if (planned_item.commande && planned_item.commande.status !== 5) {
                            commandes_not_finished_tmp += 1;
                        }
                    });
                    if (commandes_not_finished_tmp !== 0) set_commandes_not_finished(commandes_not_finished_tmp);
                }
            } catch (e) {
                console.log(e);
            }
        }
        if (deplacement?.id) fetch_deplacement_datas();
    }, [deplacement]);

    useEffect(() => {
        async function fetch_remarques() {
            try {
                const { data: remarques_found } = await getRemarquesDeplacement(current_personnel.id, deplacement.id);
                if (remarques_found?.length > 0) set_remarques(remarques_found);
            } catch (e) {
                console.log(e);
                toast.error("Impossible de récupérer les remarques de ce déplacement");
            }
        }
        function format_tel(tel_number) {
            if (!tel_number) return "";
            let formated_tel = tel_number.replaceAll(".", "");
            formated_tel = formated_tel.replaceAll(" ", "");
            formated_tel = formated_tel.replaceAll("-", "");
            formated_tel = formated_tel.replaceAll("/", "");
            formated_tel = formated_tel.substring(1);
            return `+33${formated_tel}`;
        }

        async function prepare_message() {
            let numeros = [];

            planned_personnels.map((planned_personnel) => {
                if (planned_personnel.personnel.id !== current_personnel.id) {
                    numeros.push(format_tel(planned_personnel.personnel.user.telephone));
                }
            });

            let link = "sms://";

            numeros.map((num) => {
                link += `${num},`;
            });

            link += "?&body=";

            let zones = "";
            if (deplacement.zones) {
                JSON.parse(deplacement.zones).map((z) => (zones += `${z}, `));
                zones = zones.slice(0, -2);
            }
            link += encodeURIComponent(`Bonjour,\n`);
            set_message_to_all(link);
        }

        if (accordion_open && !remarques) fetch_remarques();

        if (deplacement) prepare_message();
    }, [accordion_open]);

    return (
        <Accordion sx={{ mb: 2 }} TransitionProps={{ unmountOnExit: true }} expanded={accordion_open}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => {
                    setAccordion_open(!accordion_open);
                }}
            >
                <Typography variant="h6">
                    {is_chef && (
                        <Badge
                            badgeContent={commandes_not_finished}
                            color="primary"
                            invisible={commandes_not_finished === 0}
                            sx={{ mr: 1 }}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                        >
                            <IoPersonCircleOutline size={30} color="#FFD700" />
                        </Badge>
                    )}
                    {display_equipe(deplacement.equipe)}
                    Déplacement n°{deplacement.numero_deplacement} {deplacement?.is_visite && `${" "}(visite)`}{" "}
                    {deplacement?.is_formation && `${" "}(formation)`}
                    <Typography color="text.secondary">Départ : {moment(deplacement.startDate).format("ddd DD/MM/YYYY à HH:mm")}</Typography>
                    <Typography color="text.secondary">Retour : {moment(deplacement.endDate).format("ddd DD/MM/YYYY")}</Typography>
                </Typography>
            </AccordionSummary>

            <AccordionDetails sx={{ backgroundColor: "#f0f0f0" }}>
                <Accordion sx={{ mb: 2 }}>
                    <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                <Typography variant="h5">Détails</Typography>
                            </Grid>
                            {remarques?.length > 0 && (
                                <React.Fragment>
                                    <ListItem>
                                        <ListItemIcon>
                                            <SpeakerNotes />
                                        </ListItemIcon>
                                        <span style={{ fontWeight: "bold" }}>Remarques</span>
                                    </ListItem>
                                    <List component="div" disablePadding>
                                        {remarques.map((r) => (
                                            <ListItemButton>{r.texte}</ListItemButton>
                                        ))}
                                    </List>
                                </React.Fragment>
                            )}
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            {deplacement.observations?.length > 0 && (
                                <React.Fragment>
                                    <ListItem>
                                        <ListItemIcon>
                                            <SpeakerNotes />
                                        </ListItemIcon>
                                        <span style={{ fontWeight: "bold" }}>Observations</span>
                                    </ListItem>
                                    <List component="div" disablePadding>
                                        {deplacement.observations}
                                    </List>
                                </React.Fragment>
                            )}

                            <ListItem>
                                <ListItemIcon>
                                    <IoPersonCircleOutline size={35} color="#FFD700" />
                                </ListItemIcon>
                                <span style={{ fontWeight: "bold" }}>Chef·fe·s</span>
                            </ListItem>
                            <List component="div" disablePadding>
                                {planned_personnels
                                    .filter((item) => item?.poste === "chef")
                                    .map((planned_chef) => (
                                        <Typography>
                                            N°{planned_chef.personnel.numero}. {planned_chef.personnel.displayableName} -{" "}
                                            {format_tel_number_link(planned_chef.personnel.user.telephone)}
                                        </Typography>
                                    ))}
                            </List>

                            {is_chef ? (
                                <React.Fragment>
                                    <React.Fragment>
                                        <ListItem>
                                            <ListItemIcon>
                                                <IoPersonCircleOutline size={35} color="#C0C0C0" />
                                            </ListItemIcon>
                                            <span style={{ fontWeight: "bold" }}>Opérateur·rice·s</span>
                                        </ListItem>
                                        <List component="div" disablePadding>
                                            {planned_personnels
                                                .filter((item) => item?.poste === "operateur")
                                                .map((planned_operateur) => (
                                                    <Typography>
                                                        N°{planned_operateur.personnel.numero}. {planned_operateur.personnel.displayableName} -{" "}
                                                        {format_tel_number_link(planned_operateur.personnel.user.telephone)}
                                                    </Typography>
                                                ))}
                                        </List>
                                    </React.Fragment>
                                    <React.Fragment>
                                        <ListItem>
                                            <ListItemIcon>
                                                <IoPersonCircleOutline size={35} color="#cd7f32" />
                                            </ListItemIcon>
                                            <span style={{ fontWeight: "bold" }}>Poseur·se·s</span>
                                        </ListItem>
                                        <List component="div" disablePadding>
                                            {planned_personnels
                                                .filter((item) => item?.poste === "poseur")
                                                .map((planned_poseur) => (
                                                    <Typography>
                                                        N°{planned_poseur.personnel.numero}. {planned_poseur.personnel.displayableName} -{" "}
                                                        {format_tel_number_link(planned_poseur.personnel.user.telephone)}
                                                    </Typography>
                                                ))}
                                        </List>
                                    </React.Fragment>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <ListItem>
                                        <ListItemIcon>
                                            <IoPersonCircleOutline size={35} color="#cd7f32" />
                                        </ListItemIcon>
                                        <span style={{ fontWeight: "bold" }}>Personnels</span>
                                    </ListItem>
                                    <List component="div" disablePadding>
                                        {planned_personnels
                                            .filter((item) => item?.poste === "poseur" || item.poste === "operateur")
                                            .map((planned_personnel) => (
                                                <Typography>
                                                    N°{planned_personnel.personnel.numero}. {planned_personnel.personnel.displayableName} -{" "}
                                                    {format_tel_number_link(planned_personnel.personnel.user.telephone)}
                                                </Typography>
                                            ))}
                                    </List>
                                </React.Fragment>
                            )}

                            {planned_camions?.length > 0 && (
                                <React.Fragment>
                                    <ListItem>
                                        <ListItemIcon>
                                            <LocalShipping />
                                        </ListItemIcon>
                                        <span style={{ fontWeight: "bold" }}>Camions</span>
                                    </ListItem>
                                    <List component="div" disablePadding>
                                        {planned_camions.map((planned_camion) => (
                                            <ListItem sx={{ pl: 4 }}>
                                                C{planned_camion.camion.numero} ({planned_camion.camion.immatriculation})
                                            </ListItem>
                                        ))}
                                    </List>
                                </React.Fragment>
                            )}
                            {deplacement.zones && (
                                <React.Fragment>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Public />
                                        </ListItemIcon>
                                        <span style={{ fontWeight: "bold" }}>Zones géographiques</span>
                                    </ListItem>
                                    <List component="div" disablePadding>
                                        <ListItem sx={{ pl: 4 }}>
                                            {JSON.parse(deplacement.zones).map((z) => (
                                                <span style={{ marginRight: "4px" }}>{z}, </span>
                                            ))}
                                        </ListItem>
                                    </List>
                                </React.Fragment>
                            )}
                        </List>
                        {is_chef && (
                            <Grid container spacing={1} direction="column" alignItems="center" justifyContent="center">
                                <Grid item xs={12} md={12}>
                                    <Button
                                        component={Link}
                                        to={`/checklists/chef/camion?id_deplacement=${deplacement.id}`}
                                        variant="outlined"
                                        endIcon={<Create />}
                                    >
                                        Préparer le déplacement
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Button
                                        variant="outlined"
                                        startIcon={<Edit />}
                                        onClick={() => prepare_create_remarque({ deplacement: deplacement.id })}
                                    >
                                        Faire une remarque sur ce déplacement
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Button variant="outlined" startIcon={<Send />}>
                                        <a href={message_to_all}>Envoyer un message aux personnels</a>
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </AccordionDetails>
                </Accordion>

                {planned_items
                    .sort((a, b) =>
                        future
                            ? moment(b.startDate).diff(moment(a.startDate)) < 0
                                ? 1
                                : -1
                            : moment(b.startDate).diff(moment(a.startDate)) > 0
                            ? 1
                            : -1
                    )
                    .map((planned_item) => {
                        if (future) {
                            if (planned_item?.camping?.id && moment(planned_item.endDate).startOf("day").diff(moment().startOf("day"), "days") < 0) {
                                return;
                            } else if (
                                planned_item?.commande?.id &&
                                moment(planned_item.startDate).startOf("day").diff(moment().startOf("day"), "days") < 0
                            ) {
                                return;
                            }
                            // Commande d'un déplacement de visite dont la visite est déjà faite
                            else if (
                                planned_item?.commande?.id &&
                                deplacement.is_visite &&
                                planned_item?.commande.is_visited &&
                                moment(planned_item.startDate).startOf("day").diff(moment().startOf("day"), "days") <= 0
                            )
                                return;
                        } else {
                            if (planned_item?.camping?.id && moment(planned_item.endDate).startOf("day").diff(moment().startOf("day"), "days") > 0) {
                                return;
                            } else if (
                                planned_item?.commande?.id &&
                                moment(planned_item.startDate).startOf("day").diff(moment().startOf("day"), "days") > 0
                            ) {
                                return;
                            }
                        }

                        // c'est une commande
                        if (is_chef && planned_item?.commande?.id) {
                            return (
                                <CommandeCard
                                    planned_commande={planned_item}
                                    deplacement={deplacement}
                                    create_remarque={prepare_create_remarque}
                                    current_personnel={current_personnel}
                                    set_selected_remarque={set_selected_remarque}
                                    future={future}
                                />
                            );
                        }
                        // c'est une nuit
                        else if (is_chef && planned_item?.camping?.id) {
                            return (
                                <PlannedCampingCard
                                    planned_camping={planned_item}
                                    create_remarque={prepare_create_remarque}
                                    current_personnel={current_personnel}
                                    set_selected_remarque={set_selected_remarque}
                                />
                            );
                        } else if (planned_item.is_formation) {
                            return <DeplacementCard planned_formation={planned_item} />;
                        }
                    })}
            </AccordionDetails>
        </Accordion>
    );
}
