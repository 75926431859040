import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
    AccountCircle,
    ExpandLess,
    ExpandMore,
    House,
    Check,
    Lightbulb,
    SpeakerNotes,
    AttachMoneyOutlined,
    AccountBoxOutlined,
    Build,
    Business,
    Public,
    Pets,
    EuroSymbol,
    Group,
    LocalShipping,
    Receipt,
    Construction,
    Call,
    Euro,
    DateRange,
    Payments,
    AccessTime,
    Settings,
    FileUpload,
    Cake,
    LockOpen,
    MailOutline,
    PermContactCalendar,
} from "@mui/icons-material";
import { openInNewTab } from "../utils";
import { DrawerSaison } from "./drawer_saison";
import { Divider, List, ListSubheader, ListItemText, Collapse, ListItemIcon, ListItem } from "@mui/material";
import { getJwt } from "../../services/authService";

import { MdMoreTime } from "react-icons/md";
import { getSaisons, getCurrentSaison } from "../../services/saisonService";
import { getCurrentUser } from "../../services/authService";
import { FaMoneyBillAlt } from "react-icons/fa";

export const DrawerAdmin = () => {
    const [saisons, setSaisons] = React.useState([]);
    const [user, setUser] = React.useState(null);

    useEffect(() => {
        try {
            async function saisonMenu() {
                const res = await getSaisons();
                if (res) {
                    setSaisons(res);
                }
            }
            const u = getCurrentUser();
            setUser(u);
            saisonMenu();
        } catch (e) {
            console.log(e);
        }
    }, []);

    const [open, setOpen] = React.useState(false);

    if (user && user.is_superuser)
        return (
            <React.Fragment>
                <List
                    sx={{
                        width: 230,
                        bgcolor: "background.paper",
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" onClick={() => setOpen(!open)}>
                            {open ? <ExpandLess /> : <ExpandMore />}
                            Général
                        </ListSubheader>
                    }
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {/* Campings */}
                        <ListItem key={"campings"} button component={Link} to="/campings">
                            <ListItemIcon>
                                <House />
                            </ListItemIcon>
                            <ListItemText primary="Campings" />
                        </ListItem>

                        {/* Checklists */}
                        <ListItem key={"checklists"} button component={Link} to="/checklists">
                            <ListItemIcon>
                                <Check />
                            </ListItemIcon>
                            <ListItemText primary="Checklists" />
                        </ListItem>

                        {/* Clients */}
                        <ListItem key={"clients"} button component={Link} to="/clients">
                            <ListItemIcon>
                                <AccountBoxOutlined />
                            </ListItemIcon>
                            <ListItemText primary="Clients" />
                        </ListItem>

                        {/* Droits utilisateurs */}
                        <ListItem key={"utilisateur"} button component={Link} to="/users">
                            <ListItemIcon>
                                <LockOpen />
                            </ListItemIcon>
                            <ListItemText primary="Droits utilisateurs" />
                        </ListItem>

                        {/* groupements */}
                        <ListItem key={"groupements"} button component={Link} to="/groupements">
                            <ListItemIcon>
                                <Business />
                            </ListItemIcon>
                            <ListItemText primary="Groupements" />
                        </ListItem>

                        {/* Idées */}
                        <ListItem key={"ideas"} button component={Link} to="/ideas">
                            <ListItemIcon>
                                <Lightbulb />
                            </ListItemIcon>
                            <ListItemText primary="Idées" />
                        </ListItem>

                        {/* paramètres */}
                        <ListItem key={"params"} button component={Link} to="/parameters">
                            <ListItemIcon>
                                <Settings />
                            </ListItemIcon>
                            <ListItemText primary="Paramètres" />
                        </ListItem>

                        {/* pinces */}
                        <ListItem key={"pince"} button component={Link} to="/pinces">
                            <ListItemIcon>
                                <Construction />
                            </ListItemIcon>
                            <ListItemText primary="Pinces" />
                        </ListItem>

                        {/* saisons */}
                        <ListItem key={"saisons"} button component={Link} to="/saisons">
                            <ListItemIcon>
                                <Cake />
                            </ListItemIcon>
                            <ListItemText primary="Saisons" />
                        </ListItem>

                        {/* souches */}
                        <ListItem key={"souches"} button component={Link} to="/souches">
                            <ListItemIcon>
                                <Pets />
                            </ListItemIcon>
                            <ListItemText primary="Souches" />
                        </ListItem>

                        {/* techniciens */}
                        <ListItem key={"techniciens"} button component={Link} to="/techniciens">
                            <ListItemIcon>
                                <Build />
                            </ListItemIcon>
                            <ListItemText primary="Techniciens" />
                        </ListItem>

                        {/* zones geo */}
                        <ListItem key={"zones"} button component={Link} to="/zones">
                            <ListItemIcon>
                                <Public />
                            </ListItemIcon>
                            <ListItemText primary="Zones géographique" />
                        </ListItem>
                    </Collapse>

                    <Divider />
                </List>
                {saisons && saisons.length > 0 && saisons.map((saison) => <DrawerSaison saison={saison} />)}
            </React.Fragment>
        );
    else if (user && user.is_staff)
        return (
            <React.Fragment>
                <List>
                    {/* Checklists */}
                    <ListItem key={"check_appel"} button component={Link} to="/checklists/chef/appel">
                        <ListItemIcon>
                            <Call />
                        </ListItemIcon>
                        <ListItemText primary="Checklist appel client" />
                    </ListItem>

                    <ListItem key={"check_camion"} button component={Link} to="/checklists/chef/camion">
                        <ListItemIcon>
                            <LocalShipping />
                        </ListItemIcon>
                        <ListItemText primary="Checklist préparation camion" />
                    </ListItem>

                    <ListItem key={"check_camping"} button component={Link} to="/checklists/chef/camping">
                        <ListItemIcon>
                            <House />
                        </ListItemIcon>
                        <ListItemText primary="Checklist préparation camping" />
                    </ListItem>

                    <ListItem key={"memo"} button component={Link} to="/fiche-horaire/edit">
                        <ListItemIcon>
                            <MdMoreTime size={25} />
                        </ListItemIcon>
                        <ListItemText primary="Faire une fiche horaire" />
                    </ListItem>

                    {/* Planning */}
                    <ListItem key={"planning"} button component={Link} to={`/me/planning`}>
                        <ListItemIcon>
                            <DateRange />
                        </ListItemIcon>
                        <ListItemText primary="Planning" />
                    </ListItem>

                    {saisons &&
                        saisons.length > 0 &&
                        saisons.map((saison) => {
                            if (saison.current_saison)
                                return (
                                    <React.Fragment>
                                        <ListItem key={"tickets"} component={Link} to={`/tickets/edit/${saison.year}/new`}>
                                            <ListItemIcon>
                                                <FaMoneyBillAlt />
                                            </ListItemIcon>
                                            <ListItemText primary="Ajouter un ticket de caisse" />
                                        </ListItem>
                                        <ListItem component={Link} to={"/personnels/saison/" + saison.year}>
                                            <ListItemIcon>
                                                <AccountCircle />
                                            </ListItemIcon>
                                            <ListItemText primary="Personnels" />
                                        </ListItem>

                                        {/* Planning camion */}
                                        <ListItem key={"planning-camions"} component={Link} to={`/planning-camions/${saison.year}`}>
                                            <ListItemIcon>
                                                <LocalShipping />
                                            </ListItemIcon>
                                            <ListItemText primary="Planning des camions" />
                                        </ListItem>
                                    </React.Fragment>
                                );
                        })}

                    {/* Idées */}
                    <ListItem key={"ideas"} component={Link} to="/ideas/edit/new">
                        <ListItemIcon>
                            <Lightbulb />
                        </ListItemIcon>
                        <ListItemText primary="Ajouter une idée" />
                    </ListItem>
                </List>
            </React.Fragment>
        );
    else if (user) {
        return (
            <List>
                {/* Planning */}
                <ListItem key={"planning"} component={Link} to={`/me/planning`}>
                    <ListItemIcon>
                        <DateRange />
                    </ListItemIcon>
                    <ListItemText primary="Planning" />
                </ListItem>
            </List>
        );
    } else return <React.Fragment></React.Fragment>;
};
