import React, { useState, useEffect } from "react";
import { Grid, Divider, Box, Fab, Stack } from "@mui/material";
import { useFieldArray } from "react-hook-form";
import { AutocompleteInput } from "../common/AutocompleteInput";
import { TimePickerInput } from "../common/TimePickerInput";
import { DateTimePickerInput } from "../common/DateTimePickerInput";
import { PersonAdd, PersonRemove } from "@mui/icons-material";
import { DataGridPro, frFR, GridToolbar } from '@mui/x-data-grid-pro';
import { Link } from "react-router-dom"
import clsx from 'clsx';
import moment from "moment"
import {
	displayField,
	displayWithIcon,
	displayWithTooltip,
	formatDuration,
} from "../utils";
import { getOperation, saveOperation } from "../../services/operationService";
import { toast } from "react-toastify"
import { on_state_change, get_density } from "../../services/uxService"
import { getParameters, saveParameter } from "../../services/parameterService";


export default function OperationTableau({ operations, editable, restricted }) {
	const [max_pourcentage_faux, set_max_pourcentage_faux] = useState(0);



	useEffect(() => {

		async function fetch_parameters() {
			try {
				const { data: param_found } = await getParameters();
				if (!param_found || param_found?.length === 0) {
					toast.info("Aucun paramètre encore enregistré")
				}
				else {
					set_max_pourcentage_faux(param_found[0].max_pourcentage_faux)
				}

			} catch (e) {
				console.log(e)
				toast.error("Impossible de récupérer les paramètres")
			}
		}

		fetch_parameters()
	}, [])

	const handleCellEditCommitHoraires = async (params) => {
		if (!editable || restricted) return;
		try {
			const { data: operation } = await getOperation(params.id)
			operation[params.field] = params.value

			if (params.row)
				params.row[params.field] = params.value
			operation.operateur = operation.operateur.id
			operation.commande = operation.commande.id

			const res = await saveOperation(operation)
			if (res.status < 200 || res.status >= 400) throw new Error("saveOperation() returned an error status code")

		} catch (e) {
			console.log(e)
			toast.error("Erreur dans la sauvegarde de l'opération")

		}
	}


	let operationsColumns = [
		// {
		// 	field: "id",
		// 	headerName: "ID",
		// 	headerAlign: "center",
		// 	align: "center",
		// 	width: 70,
		// },
		{
			field: "nombre",
			headerName: "Nombre",
			headerAlign: "center",
			type: "number",
			editable: editable && !restricted,
			align: "center",
			sortable: false,
			width: 130,
		},
		{
			field: "operateur",
			headerName: "Opérateur",
			headerAlign: "center",
			sortable: false,
			width: 200,
			renderCell: ({ value }) => value ? value?.displayableName : ""
		},
		{
			field: "commande",
			headerName: "Commande",
			sortable: false,
			headerAlign: "center",
			width: 250,
			renderCell: ({ value }) => value ? (restricted ?
				`N°${value.numero_commande} - ${value.client?.nom}`
				: <Link to={`/commandes/view/${value.id}`}>
					N°{value.numero_commande} - {value.client?.nom}
				</Link>) : ""
		},
		{
			field: "commande__taux_moyenne_basse",
			headerName: "Taux moyen bas",
			sortable: false,
			headerAlign: "center",
			valueGetter: (params) => params.row?.commande?.taux_moyenne_basse,
			align: "center",
			width: 150,
			renderCell: ({ value }) => value ? `${value} %` : ""
		},
		{
			field: "commande__taux_moyenne_haute",
			headerName: "Taux moyen haut",
			align: "center",
			sortable: false,
			headerAlign: "center",
			valueGetter: (params) => params.row?.commande?.taux_moyenne_haute,
			width: 150,
			renderCell: ({ value }) => value ? `${value} %` : ""
		},
		{
			field: "commande__taux_moyenne",
			headerName: "Taux moyen",
			sortable: false,
			align: "center",
			headerAlign: "center",
			valueGetter: (params) => params.row?.commande?.taux_moyenne,
			width: 150,
			renderCell: ({ value }) => value ? `${value} %` : "",
			cellClassName: (params) => {
				if (params.value == null) {
					return '';
				}

				return clsx('super-app', {
					ko: params.value > max_pourcentage_faux,
					ok: params.value <= max_pourcentage_faux,
				});
			},
		}
	];



	return (
		<Box
			sx={{
				'& .super-app.ok': {
					backgroundColor: 'rgba(157, 255, 118, 0.49)',
					color: '#1a3e72',
					fontWeight: '600',
				},
				'& .super-app.ko': {
					backgroundColor: '#d47483',
					color: '#1a3e72',
					fontWeight: '600',
				},
			}}
		>
			<DataGridPro

				density={get_density()}
				onStateChange={on_state_change}
				components={{ Toolbar: GridToolbar }}
				onCellEditCommit={handleCellEditCommitHoraires}
				localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
				disableColumnFilter
				autoHeight
				rows={operations}
				columns={operationsColumns}

				onCellClick={(params, event) => {
					if (restricted) return;
					if (!event.ctrlKey) {
						event.defaultMuiPrevented = true;
					}
					return;
				}}
			/>
		</Box >

	);
}
