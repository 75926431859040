import http from "./httpService";
import {get_api_url } from "../components/utils"


const apiEndpoint = get_api_url() + "/bons-facturation";

function bonUrl(id) {
	return `${apiEndpoint}/${id}`;
}
export function getBonsCurrentSaison() {
	return http.get(
		`${apiEndpoint}/?commande__saison__current_saison=true&ordering=commande__date`
	);
}

export function getBons(year=null) {
	return http.get(`${apiEndpoint}/?ordering=commande__date&commande__saison__year=${year}`);
}

export function getBonCommande(id_commande) {
	return http.get(`${apiEndpoint}/?commande__id=${id_commande}`);
}

export function sendBonEmail(id_bon) {
	return http.post(`${get_api_url()}/send-bon-facturation/`, `{"id":${id_bon}}`)
}

export function getBon(BonId) {
	return http.get(`${bonUrl(BonId)}/`);
}

export function saveBon(bon) {
	if (bon.id) {
		const body = { ...bon };
		let res;
		try {
			res = http.put(bonUrl(bon.id) + "/", body);
		} catch (error) {
			console.error(error.response.data); // NOTE - use "error.response.data` (not "error")
		}

		return res;
	}

	return http.post(apiEndpoint + "/", bon);
}

export function deleteBon(bonId) {
	return http.delete(`${bonUrl(bonId)}/`);
}
