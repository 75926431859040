import React, { useState, useEffect } from "react";
import {
    List,
    ListItem,
    ListItemText,
    Paper,
    Checkbox,
    ListItemButton,
    Typography,
    Button,
    Alert,
    AlertTitle,
    TableContainer,
    TableBody,
    Table,
} from "@mui/material";
import { display_row } from "../../utils";
import { getChecklistsType } from "../../../services/checklistService";
import { getParameters } from "../../../services/parameterService";
import { getDeplacement } from "../../../services/deplacementService";
import { toast } from "react-toastify";
import moment from "moment";
import { get_planned_personnels } from "../../../services/plannedPersonnelService";
import { get_planned_items_from_commande } from "../../../services/plannedItemService";
import { useSearchParams } from "react-router-dom";

function DisplayDeplacementNeeds({ deplacement, parameters }) {
    const [nb_personnels, set_nb_personnels] = useState(0);
    const [nb_operateurs, set_nb_operateurs] = useState(0);
    const [nb_chantiers, set_nb_chantiers] = useState(0);
    const [nb_gants_s, set_nb_gants_s] = useState(0);
    const [nb_gants_l, set_nb_gants_l] = useState(0);
    const [nb_gants_m, set_nb_gants_m] = useState(0);
    const [quantite_antibio, set_quantite_antibio] = useState(0);

    useEffect(() => {
        async function fetch_deplacement_data() {
            try {
                let nb_gants_l_tmp = 0,
                    nb_gants_m_tmp = 0,
                    nb_gants_s_tmp = 0,
                    quantite_antibio_tmp = 0;

                const { data: planned_personnels } = await get_planned_personnels(deplacement.id);
                set_nb_personnels(planned_personnels?.length);

                let operateurs = planned_personnels.filter((planned_perso) => planned_perso.poste === "chef" || planned_perso.poste === "operateur");
                set_nb_operateurs(operateurs.length);

                const { data: planned_items } = await get_planned_items_from_commande("", deplacement.id);
                const planned_commandes = planned_items.filter((item) => item?.commande?.id);
                set_nb_chantiers(planned_commandes?.length);

                operateurs?.map((operateur) => {
                    if (operateur.personnel.taille_gant === "S") nb_gants_s_tmp += 1;
                    else if (operateur.personnel.taille_gant === "M") nb_gants_m_tmp += 1;
                    else if (operateur.personnel.taille_gant === "L") nb_gants_l_tmp += 1;
                });

                planned_commandes.map((planned_commande) => {
                    if (planned_commande.commande.notre_antibio) quantite_antibio_tmp += planned_commande.commande.quantite * 0.2;
                });

                set_nb_gants_l(nb_gants_l_tmp);
                set_nb_gants_s(nb_gants_s_tmp);
                set_nb_gants_m(nb_gants_m_tmp);
                set_quantite_antibio(quantite_antibio_tmp);
            } catch (e) {
                console.log(e);
            }
        }

        if (deplacement?.id && parameters) fetch_deplacement_data();
    }, [deplacement, parameters]);

    return (
        <React.Fragment>
            <Typography sx={{ my: 3 }} variant="h5">
                Déplacement n°{deplacement.numero_deplacement} du {moment(deplacement.startDate).format("ddd DD/MM")} au{" "}
                {moment(deplacement.endDate).format("ddd DD/MM")}
            </Typography>
            <TableContainer component={Paper} sx={{ maxWidth: 900, mb: 2 }}>
                <Table sx={{ maxWidth: 900 }} aria-label="simple table" size="small">
                    <TableBody>
                        {display_row("Nombre de chantiers", nb_chantiers)}
                        {display_row(
                            "Nombre de jours",
                            moment(deplacement.endDate).startOf("day").diff(moment(deplacement.startDate).startOf("day"), "days") + 1
                        )}
                        {display_row("Nombre de personnels", `${nb_personnels} (dont ${nb_operateurs} opérateurs)`)}
                        {display_row("Gants S", Math.ceil(nb_gants_s * nb_chantiers * parameters.quantite_gant_operateur))}
                        {display_row("Gants M", Math.ceil(nb_gants_m * nb_chantiers * parameters.quantite_gant_operateur))}
                        {display_row("Gants L", Math.ceil(nb_gants_l * nb_chantiers * parameters.quantite_gant_operateur))}
                        {display_row("Lames", Math.ceil(nb_operateurs * nb_chantiers * parameters.quantite_lame_operateur))}
                        {display_row("Piles", Math.ceil(nb_operateurs * nb_chantiers * parameters.quantite_pile_operateur))}
                        {display_row("Hibitane", `${Math.ceil(nb_operateurs * nb_chantiers * parameters.quantite_hibitane_operateur_ml)} mL`)}
                        {display_row("Combinaisons", Math.ceil(nb_chantiers * nb_personnels * parameters.quantite_combi_operateur))}
                        {display_row("Pédibottes", Math.ceil(nb_personnels * nb_chantiers * parameters.quantite_pedibottes_personnel))}
                        {display_row("Seringues", Math.ceil(nb_personnels - nb_operateurs + 1))}
                        {display_row("Antibio", `${Math.ceil(quantite_antibio)} mL`)}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
}

export default function ChecklistChef({ type }) {
    const [checked, setChecked] = useState([]);
    const [elements, set_elements] = useState([]);
    const [deplacement, set_deplacement] = useState();
    const [parameters, set_parameters] = useState();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        async function fetch_elements() {
            try {
                const { data: elements_found } = await getChecklistsType(type);
                if (!elements_found) throw new Error("No data returned by getChecklistsType");
                set_elements(elements_found);

                if (type !== "CAMION" || !searchParams.get("id_deplacement") || !searchParams) return;

                const { data: parameters_found } = await getParameters();
                set_parameters(parameters_found[0]);
                const { data: deplacement_found } = await getDeplacement(parseInt(searchParams.get("id_deplacement")));
                set_deplacement(deplacement_found);
            } catch (e) {
                console.log(e);
                toast.error("Impossible de récupérer les éléments");
            }
        }

        set_deplacement(null);
        if (type) fetch_elements();
    }, [type]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    return (
        <React.Fragment>
            <Typography variant="h4">Checklist {type?.toLowerCase()}</Typography>
            {deplacement && <DisplayDeplacementNeeds deplacement={deplacement} parameters={parameters} />}

            {checked?.length !== elements?.length && (
                <Alert severity="warning">
                    <AlertTitle>Cases non cochées</AlertTitle>
                    Une ou plusieurs cases ne sont pas cochées, vérifie bien !
                </Alert>
            )}

            <List dense sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
                {elements &&
                    elements.map((element) => {
                        const labelId = `checkbox-list-secondary-label-${element.id}`;
                        return (
                            <ListItem
                                key={element.id}
                                onClick={handleToggle(element.id)}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={handleToggle(element.id)}
                                        checked={checked.indexOf(element.id) !== -1}
                                        inputProps={{ "aria-labelledby": labelId }}
                                    />
                                }
                                disablePadding
                            >
                                <ListItemButton>
                                    <ListItemText id={labelId} primary={element.texte} />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
            </List>
            <Button sx={{ mt: 5 }} variant="outlined" onClick={() => setChecked([])}>
                Réinitialiser
            </Button>
        </React.Fragment>
    );
}
