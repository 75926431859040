import http from "./httpService";
import { get_api_url } from "../components/utils"
import moment from "moment";

const apiEndpoint = get_api_url() + "/planned-items";


function planned_item_url(id) {
	return `${apiEndpoint}/${id}/`;
}


export function get_saison_planned_items(year_saison) {
	return http.get(
		apiEndpoint + "/?ordering=startDate&deplacement__saison__year=" + year_saison
	);
}

export function get_client_planned_items(id_client, year_saison) {
	return http.get(
		apiEndpoint + "/?ordering=startDate&item__client__id=" + id_client + "&item__saison__year=" + year_saison
	);
}

export function get_planned_items_from_commande(id_commande, id_deplacement = "") {
	return http.get(
		apiEndpoint + "/?ordering=startDate&commande__id=" + id_commande + "&deplacement__id=" + id_deplacement
	);
}

export function get_campings_planned_items(id_camping, id_deplacement = "") {
	return http.get(
		apiEndpoint + "/?ordering=startDate&camping__id=" + id_camping + "&deplacement__id=" + id_deplacement
	);
}

export function get_deplacement_commandes_planned_items(id_deplacement) {
	return http.get(
		apiEndpoint + "/?ordering=startDate&commande__isnull=false&deplacement__id=" + id_deplacement
	);
}


export function get_deplacement_planned_items(id_deplacement) {
	return http.get(
		apiEndpoint + "/?ordering=startDate&deplacement__id=" + id_deplacement
	);
}

export function get_deplacement_planned_items_date(id_deplacement,date) {
	return http.get(
		apiEndpoint + "/?ordering=startDate&deplacement__id=" + id_deplacement + "&startDate__gte=" + moment(date).format("YYYY-MM-DDT00:00:01") + "&endDate__lte=" + moment(date).format("YYYY-MM-DDT23:59:59")
	);
}


export function get_planned_item(planned_item_id) {
	return http.get(planned_item_url(planned_item_id));
}

export function save_planned_item(planned_item) {
	if (planned_item.id) {
		const body = { ...planned_item };
		return http.put(planned_item_url(planned_item.id), body);
	}

	return http.post(`${apiEndpoint}/`, planned_item);
}

export function delete_planned_item(planned_item_id) {
	return http.delete(planned_item_url(planned_item_id));
}
