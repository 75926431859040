import http from "./httpService";
import {get_api_url } from "../components/utils"


const apiEndpoint = get_api_url() + "/fiches-chaponnage";

function ficheUrl(id) {
	return `${apiEndpoint}/${id}/`;
}

export function sendFicheEmail(idFiche) {
	return http.post(`${get_api_url()}/send-fiche-chaponnage/`, `{"id":${idFiche}}`)
}

export function getFicheCommande(commandeId) {
	return http.get(apiEndpoint + "/?commande__id=" + commandeId);
}

export function getFiches() {
	return http.get(apiEndpoint);
}

export function getFiche(ficheId) {
	return http.get(ficheUrl(ficheId));
}

export function saveFiche(fiche) {
	if (fiche.id) {
		const body = { ...fiche };
		let res;
		try {
			res = http.put(ficheUrl(fiche.id), body);
		} catch (error) {
			console.error(error.response.data); // NOTE - use "error.response.data` (not "error")
		}
		return res;
	}

	return http.post(apiEndpoint + "/", fiche);
}

export function deleteFiche(ficheId) {
	return http.delete(ficheUrl(ficheId));
}
