import React, { useState, useEffect } from "react";
import {
    Grid,
    Typography,
    Box,
    Accordion,
    ButtonGroup,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardContent,
    CardActions,
    Button,
    Table,
    IconButton,
    CardHeader,
    List,
    ListItem,
    ListItemButton,
    TableBody,
    Menu,
    MenuItem,
    ListItemIcon,
    Dialog,
    DialogContent,
    DialogActions,
    TableContainer,
    Paper,
} from "@mui/material";
import { getRemarquesPlannedItem } from "../../services/remarqueChefService";
import { saveCommande } from "../../services/commandeService";
import { get_chefs_deplacement } from "../../services/plannedPersonnelService";
import { toast } from "react-toastify";
import moment from "moment";
import { ExpandMore, MoreVert, ArrowBack, SpeakerNotes, Navigation, Create, Check } from "@mui/icons-material";
import { format_tel_number_link, displayWithIcon, display_row, display_equipe, openInNewTab } from "../utils";
import ChecklistChef from "../checklists/chefs/checklist_chef";
import CommandeAdministratif from "./commande_administratif";
import { FaWaze } from "react-icons/fa";
import { SiGooglemaps } from "react-icons/si";
import { get_planned_items_from_commande } from "../../services/plannedItemService";
import FindCamping from "./find_camping";

const array_commande = (commande) => {
    return (
        <TableContainer component={Paper} sx={{ my: 2 }}>
            <Table sx={{ maxWidth: 900 }} aria-label="simple table" size="small">
                <TableBody>
                    {display_row(
                        <span>
                            Volaille
                            <br />
                            Souche
                            <br />
                            Âge
                            <br />
                            MAJ
                        </span>,
                        <span>
                            {commande?.quantite} {commande?.volaille}
                            <br />
                            {commande?.souche ? commande?.souche?.nom : ""}
                            <br />
                            {commande?.date_mise_en_place && commande?.date
                                ? `${moment(commande.date).diff(moment(commande?.date_mise_en_place), "days")} jours`
                                : ""}
                            <br />
                            {commande?.mise_a_jeun ? commande?.mise_a_jeun : "??"}
                        </span>
                    )}
                    {display_row(
                        "Ec / Eb / Inj / NA ",
                        <React.Fragment>
                            {displayWithIcon(commande.ecretage)}
                            {displayWithIcon(commande.ebarbillonage)}
                            {displayWithIcon(commande.injection)}
                            {displayWithIcon(commande.notre_antibio)}
                        </React.Fragment>
                    )}

                    {display_row(
                        "Téléphones",
                        <React.Fragment>
                            <Box>{format_tel_number_link(commande?.client?.portable)}</Box>
                            {commande.client.telephone ? <Box>{format_tel_number_link(commande?.client?.telephone)} </Box> : ""}
                            {commande.client.portable_bis ? <Box>{format_tel_number_link(commande?.client?.portable_bis)} </Box> : ""}
                        </React.Fragment>
                    )}
                    {display_row(
                        "Adresse du chantier",
                        <React.Fragment>
                            {commande?.client?.adresse_chantier}
                            {commande?.batiment ? ` (Bâtiment : ${commande.batiment})` : ""}
                        </React.Fragment>
                    )}
                    {display_row("Points cardinaux", commande?.client?.points_cardinaux)}
                    {commande?.remarque_planning && display_row("Remarque planning", commande.remarque_planning)}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export function CommandeCard({ planned_commande, create_remarque, current_personnel, set_selected_remarque, deplacement }) {
    const [remarques, set_remarques] = useState(null);
    const [planned_deplacements, set_planned_deplacements] = useState([]);
    const [appel_commande, set_appel_commande] = useState(null);
    const [points_cardinaux, set_points_cardinaux] = useState(null);

    const [anchorEl, setAnchorEl] = useState(null);
    const [appel_j_2_done, set_appel_j_2_done] = useState(planned_commande.commande.appel_j_moins_2);
    const [commande_is_visited, set_commande_is_visited] = useState(planned_commande.commande.is_visited);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        async function get_remarques() {
            if (!current_personnel || !planned_commande) return;
            try {
                const res = await getRemarquesPlannedItem(current_personnel.id, planned_commande.id);
                if (res.status < 200 || res.status >= 400) throw new Error("Error from commandes");
                set_remarques(res.data);

                const { data: planned_items_found } = await get_planned_items_from_commande(planned_commande.commande.id);
                let deplacement_found = [];
                await Promise.all(
                    planned_items_found.map(async (planned_item) => {
                        try {
                            const { data: planned_chefs_found } = await get_chefs_deplacement(planned_item.deplacement.id);
                            deplacement_found.push({ deplacement: planned_item.deplacement, chefs: planned_chefs_found });
                        } catch (e) {
                            console.log(e);
                        }
                    })
                );
                set_planned_deplacements(deplacement_found);
            } catch (e) {
                console.log(e);
                toast.error("Impossible de récupérer les remarques");
            }
        }

        if (planned_commande?.commande.id) get_remarques();
    }, [planned_commande]);

    async function onUpdateCommande(commande, visited = null) {
        try {
            let new_commande = { id: commande.id, client: commande.client.id };
            new_commande.client = new_commande.client.id;
            if (visited != null) {
                new_commande.is_visited = !commande_is_visited;
                set_commande_is_visited(!commande_is_visited);
            } else {
                new_commande.appel_j_moins_2 = !appel_j_2_done;
                set_appel_j_2_done(!appel_j_2_done);
            }
            const res = await saveCommande(new_commande);
            if (res.status < 200 || res.status >= 400) throw new Error("saveCommande() returned an error status code");
        } catch (e) {
            console.log(e);
            toast.error("Erreur dans la mise à jour de la commande");
        }
    }

    return (
        <Card sx={{ minWidth: 275, my: 2 }}>
            <CardHeader
                action={
                    <React.Fragment>
                        <IconButton aria-label="settings" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
                            <MoreVert />
                        </IconButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                        >
                            <MenuItem
                                onClick={() => {
                                    handleClose();
                                    create_remarque({ planned_item: planned_commande.id });
                                }}
                            >
                                Faire une remarque sur cette commande
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleClose();
                                    set_points_cardinaux(planned_commande?.commande?.client?.points_cardinaux);
                                }}
                            >
                                Trouver un camping proche
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleClose();
                                    openInNewTab(
                                        `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${planned_commande?.commande?.client?.points_cardinaux}`
                                    );
                                }}
                            >
                                <SiGooglemaps />
                                Google Maps
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleClose();
                                    openInNewTab(
                                        `https://www.waze.com/ul?ll=${planned_commande?.commande?.client?.points_cardinaux.replace(
                                            ",",
                                            "%2C"
                                        )}&navigate=yes&zoom=17`
                                    );
                                }}
                            >
                                <FaWaze />
                                Waze
                            </MenuItem>
                        </Menu>
                    </React.Fragment>
                }
                title={
                    <Typography
                        variant="h5"
                        component="div"
                        sx={{ backgroundColor: "#f6b26b", fontWeight: "bold", backgroundSize: "contain", px: 0.5, py: 0.5 }}
                    >
                        {planned_commande.commande.client?.nom}
                        <Typography color="text.secondary">{planned_commande.commande.client?.elevage}</Typography>
                    </Typography>
                }
            />
            <CardContent sx={{ my: -3 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                        <Typography display="inline">
                            {" "}
                            N°{planned_commande.commande.numero_commande} -{" "}
                            {moment(deplacement?.is_visite ? planned_commande.commande.date_visite : planned_commande.commande.date).format(
                                "ddd DD MMM à HH:mm"
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        {!deplacement?.is_visite ? (
                            planned_commande.commande?.date && !appel_j_2_done ? (
                                <Button
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                    onClick={() => set_appel_commande(planned_commande.commande)}
                                >
                                    Appel J-2 à faire
                                </Button>
                            ) : (
                                <Button
                                    variant="outlined"
                                    size="small"
                                    startIcon={<Check />}
                                    onClick={() => onUpdateCommande(planned_commande.commande)}
                                >
                                    Appel J-2 fait
                                </Button>
                            )
                        ) : planned_commande.commande?.date_visite && !commande_is_visited ? (
                            <Button
                                variant="outlined"
                                size="small"
                                color="secondary"
                                onClick={() => onUpdateCommande(planned_commande.commande, true)}
                            >
                                Visite à faire
                            </Button>
                        ) : (
                            <Button
                                variant="outlined"
                                size="small"
                                startIcon={<Check />}
                                onClick={() => onUpdateCommande(planned_commande.commande, false)}
                            >
                                Visite faite
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {!deplacement?.is_visite &&
                                planned_deplacements.map((planned_deplacement) => (
                                    <div>
                                        {planned_deplacement.deplacement?.equipe ? (
                                            display_equipe(planned_deplacement.deplacement?.equipe)
                                        ) : (
                                            <span></span>
                                        )}{" "}
                                        <span style={{ width: 20 }}>
                                            {planned_deplacement.chefs.map((planned_chef) => planned_chef.personnel.displayableName)}{" "}
                                        </span>
                                    </div>
                                ))}
                        </Typography>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} sx={{ my: 2 }}>
                    <Table sx={{ maxWidth: 900 }} aria-label="simple table" size="small">
                        <TableBody>
                            {display_row(
                                <span>
                                    Volaille
                                    <br />
                                    Souche
                                    <br />
                                    Âge
                                    <br />
                                    MAJ
                                </span>,
                                <span>
                                    {planned_commande.commande?.quantite} {planned_commande.commande?.volaille}
                                    <br />
                                    {planned_commande.commande?.souche ? planned_commande.commande?.souche?.nom : ""}
                                    <br />
                                    {planned_commande.commande?.date_mise_en_place
                                        ? `${moment(planned_commande.startDate).diff(
                                              moment(planned_commande.commande?.date_mise_en_place),
                                              "days"
                                          )} jours`
                                        : ""}
                                    <br />
                                    {planned_commande.commande?.mise_a_jeun ? planned_commande.commande?.mise_a_jeun : "??"}
                                </span>
                            )}
                            {display_row(
                                "Ec / Eb / Inj / NA ",
                                <React.Fragment>
                                    {displayWithIcon(planned_commande.commande.ecretage)}
                                    {displayWithIcon(planned_commande.commande.ebarbillonage)}
                                    {displayWithIcon(planned_commande.commande.injection)}
                                    {displayWithIcon(planned_commande.commande.notre_antibio)}
                                </React.Fragment>
                            )}

                            {display_row(
                                "Téléphones",
                                <React.Fragment>
                                    <Box>{format_tel_number_link(planned_commande.commande?.client?.portable)}</Box>
                                    {planned_commande.commande.client.telephone ? (
                                        <Box>{format_tel_number_link(planned_commande.commande?.client?.telephone)} </Box>
                                    ) : (
                                        ""
                                    )}
                                    {planned_commande.commande.client.portable_bis ? (
                                        <Box>{format_tel_number_link(planned_commande.commande?.client?.portable_bis)} </Box>
                                    ) : (
                                        ""
                                    )}
                                </React.Fragment>
                            )}
                            {display_row(
                                "Adresse du chantier",
                                <React.Fragment>
                                    {planned_commande.commande?.client?.adresse_chantier}
                                    {planned_commande.commande?.batiment ? ` (Bâtiment : ${planned_commande.commande.batiment})` : ""}
                                </React.Fragment>
                            )}
                            {display_row("Temps de route", planned_commande.temps_route)}
                            {display_row("Points cardinaux", planned_commande.commande?.client?.points_cardinaux)}
                            {planned_commande.commande?.remarque_planning &&
                                display_row("Remarque planning", planned_commande.commande.remarque_planning)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
            {remarques?.length > 0 && (
                <React.Fragment>
                    <ListItem>
                        <ListItemIcon>
                            <SpeakerNotes />
                        </ListItemIcon>
                        <span style={{ fontWeight: "bold" }}>Remarques</span>
                    </ListItem>
                    <List component="div" disablePadding sx={{ mb: 2 }}>
                        {remarques.map((r) => (
                            <span onClick={() => set_selected_remarque(r)}>
                                <ListItemButton>{r.texte}</ListItemButton>
                            </span>
                        ))}
                    </List>
                </React.Fragment>
            )}
            <Grid container spacing={1} align="center" justifyContent="center">
                <Grid item md={12} xs={12}>
                    <CommandeAdministratif commande={planned_commande.commande} is_visite={deplacement?.is_visite} />
                </Grid>
            </Grid>

            <Dialog open={appel_commande} onClose={() => set_appel_commande(null)}>
                {appel_commande && (
                    <React.Fragment>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item md={12} xs={12}>
                                    <ChecklistChef type="APPEL" />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <Typography variant="h4">Commande</Typography>
                                    <TableContainer component={Paper} sx={{ my: 2 }}>
                                        <Table sx={{ maxWidth: 900 }} aria-label="simple table" size="small">
                                            <TableBody>
                                                {display_row("Client", `${appel_commande.client.nom} ${appel_commande.client.elevage}`)}
                                                {display_row("Groupement", appel_commande.client.groupement?.nom)}
                                                {display_row("Date", moment(appel_commande.date).format("ddd DD/MM/YYYY à HH:mm"))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {array_commande(appel_commande)}
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </React.Fragment>
                )}
                <DialogActions>
                    <Button onClick={() => set_appel_commande(null)}>Annuler</Button>
                    <Button
                        onClick={() => {
                            onUpdateCommande(appel_commande);
                            set_appel_commande(null);
                        }}
                    >
                        Marquer l'appel comme fait
                    </Button>
                </DialogActions>
            </Dialog>
            <FindCamping points_cardinaux={points_cardinaux} set_points_cardinaux={set_points_cardinaux} />
        </Card>
    );
}
