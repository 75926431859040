import http from "./httpService";
import {get_api_url } from "../components/utils"


const apiEndpoint = get_api_url() + "/checklists";

function checklistUrl(id) {
    return `${apiEndpoint}/${id}/`;
}

export function getChecklists() {
    return http.get(`${apiEndpoint}/?ordering=position`);
}

export function getChecklistsType(type) {
    return http.get(`${apiEndpoint}/?type=${type}&ordering=position`);
}

export function getChecklist(checklistId) {
    return http.get(checklistUrl(checklistId));
}

export function saveChecklist(checklist) {
    if (checklist.id) {
        const body = { ...checklist };
        return http.put(`${checklistUrl(checklist.id)}`, body);
    }

    return http.post(`${apiEndpoint}/`, checklist);
}

export function deleteChecklist(checklistId) {
    return http.delete(checklistUrl(checklistId));
}
