import http from "./httpService";
import {get_api_url } from "../components/utils"



const apiEndpoint = get_api_url() + "/personnels";

function personnelUrl(id) {
    return `${apiEndpoint}/${id}/`;
}

export function getPersonnelPlanning(year) {
    return http.get(`${get_api_url()}/planning-personnel/${year}/`);
}

export function getPersonnelPlanningBis(year) {
    return http.get(`${get_api_url()}/planning-personnel-bis/${year}/`);
}

export function get_personnel_dispo(year, startDate, endDate, remunerations) {
    return http.post(`${get_api_url()}/personnel-disponibilite/`, {year, startDate, endDate, remunerations});
}


export function getPersonnelPlanningMe(personnel) {
    return http.get(`${get_api_url()}/planning-personnel/${personnel?.saison?.year}/?users=${personnel?.user?.id}`);
}

export function getCurrentPersonnel(userId) {
    return http.get(`${apiEndpoint}/?saison__current_saison=true&user__id=${userId}`);
}


export function getRelatedPersonnels(userId) {
    return http.get(`${apiEndpoint}/?user__id=${userId}`);
}

export function getPersonnelsCurrentSaison() {
    return http.get(
        `${apiEndpoint}/?ordering=displayableName&saison__current_saison=true`
    );
}

export function getPersonnels(saison = null, ordering = "displayableName") {
    if (saison) {
        return http.get(
            `${apiEndpoint}/?ordering=${ordering}&saison__year=${saison}`
        );
    }
    return http.get(`${apiEndpoint}/?ordering=user__last_name`);
}

export function userIsInSaison(userId, saisonYear) {
    return http.get(
        `${apiEndpoint}/?saison__year=${saisonYear}&user__id=${userId}`
    );
}

export function getPersonnel(PersonnelId) {
    return http.get(personnelUrl(PersonnelId));
}

export function getPersonnelSaisonNumero(saison_year, numero) {
    return http.get(
        `${apiEndpoint}/?ordering=user__last_name&saison__year=${saison_year}&numero=${numero}`
    );
}

export function savePersonnel(personnel) {
    if (personnel.id) {
        const body = { ...personnel };
        return http.put(`${personnelUrl(personnel.id)}`, body);
    }

    return http.post(`${apiEndpoint}/`, personnel);
}




export function deletePersonnel(personnelId) {
    return http.delete(personnelUrl(personnelId));
}
