import jwtDecode from "jwt-decode";
import http from "./httpService";
import {get_api_create_url, get_root_url } from "../components/utils"
const apiEndpoint = get_api_create_url();

http.setJwt(getJwt());

export async function login(username, password) {
	const res = await http.post(apiEndpoint, {
		username: username,
		password: password,
	});
	const jwt = res.data
	localStorage.setItem("token", jwt.access);
	localStorage.setItem("refresh", jwt.refresh);
	localStorage.setItem("density", "standard");
}


export function loginWithJwt(jwt) {
	localStorage.setItem("token", jwt);
}

export function logout() {
	localStorage.removeItem("token");
	localStorage.removeItem("refresh");
	localStorage.removeItem("currentSaison");
	localStorage.removeItem("saisons");
	localStorage.removeItem("user_data");
}

export function getCurrentUser() {
	try {
		const jwt_decoded = jwtDecode(getJwt())
		return {
			"id": jwt_decoded.id,
			"is_staff": jwt_decoded.is_staff,
			"is_superuser": jwt_decoded.is_superuser,
			"username": jwt_decoded.user_id,
		}
	} catch (ex) {
		logout()
		return null;
	}
}

export function getJwt() {
	return localStorage.getItem("token");
}

export async function changePassword(current_password, new_password, re_new_password) {
	const data = {
		"current_password": current_password,
		"new_password": new_password
	}
	return http.post(`${get_root_url()}/auth/users/set_password/`, data)
}

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
};
