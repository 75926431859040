import React, { useState } from "react";
import {
    Grid,
    Divider,
    Box,
    Fab,
    Stack,
    Dialog,
    DialogContent,
    DialogContentText,
    Backdrop,
    CircularProgress,
    Alert,
    DialogTitle,
    Button,
    Typography,
    AlertTitle,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from "@mui/material";
import { useFieldArray } from "react-hook-form";
import { AutocompleteInput } from "../common/AutocompleteInput";
import { TimePickerInput } from "../common/TimePickerInput";
import { DateTimePickerInput } from "../common/DateTimePickerInput";
import { PersonAdd, PersonRemove } from "@mui/icons-material";
import { DataGridPro, frFR, GridToolbar } from "@mui/x-data-grid-pro";
import { Link } from "react-router-dom";
import moment from "moment";
import { ExpandMore } from "@mui/icons-material";
import { on_state_change, get_density } from "../../services/uxService";
import { displayField, displayWithIcon, displayWithTooltip, formatDuration } from "../utils";
import { getHorairesPersonnel, saveHoraire, getHoraire, deleteHoraire, getHorairesFiche } from "../../services/horaireService";
import { deleteFicheHoraire, saveFicheHoraire, getFicheHoraire } from "../../services/ficheHoraireService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function HoraireTableau({ horaires, editable, personnel, commande, restricted, height = 900 }) {
    const navigate = useNavigate();
    let horairesColumns = [
        {
            field: "id",
            headerName: "ID",
            headerAlign: "center",
            align: "center",
            width: 70,
        },
        // {
        // 	field: "date",
        // 	headerName: "Date",
        // 	headerAlign: "center",
        // 	type: "date",
        // 	editable: editable,
        // 	align: "center",
        // 	width: 115,
        // 	renderCell: ({ value }) => moment(value).format("DD/MM/YYYY")
        // },
        {
            field: "debut",
            headerName: "Début",
            headerAlign: "center",
            type: "dateTime",
            editable: editable && !restricted,
            align: "center",
            sortable: true,
            width: 130,
            renderCell: ({ value }) => moment(value).format("DD/MM/YYYY HH:mm"),
        },
        {
            field: "fin",
            headerName: "Fin",
            headerAlign: "center",
            type: "dateTime",
            sortable: true,
            editable: editable && !restricted,
            align: "center",
            width: 130,
            renderCell: ({ value }) => moment(value).format("DD/MM/YYYY HH:mm"),
        },
        {
            field: "pause",
            headerName: "Pause",
            headerAlign: "center",
            editable: editable && !restricted,
            align: "center",
            width: 90,
        },

        {
            field: "as_chef",
            headerName: "Chef",
            headerAlign: "center",
            editable: editable && !restricted,
            type: "boolean",
            align: "center",
            width: 75,
            renderCell: ({ value }) => displayWithIcon(value),
        },

        {
            field: "as_conducteur",
            headerName: "Conducteur",
            headerAlign: "center",
            editable: editable && !restricted,
            type: "boolean",
            width: 110,
            align: "center",
            renderCell: ({ value }) => displayWithIcon(value),
        },
        {
            field: "as_passager",
            headerName: "Passager",
            headerAlign: "center",
            editable: editable && !restricted,
            type: "boolean",
            align: "center",
            width: 90,
            renderCell: ({ value }) => displayWithIcon(value),
        },
        {
            field: "camion",
            headerName: "Camion",
            headerAlign: "center",
            align: "center",
            valueGetter: (params) => (params.row?.camion ? `C${params.row?.camion?.numero}` : ""),
            width: 80,
        },
        {
            field: "fiche_horaire__images",
            headerName: "Images",
            headerAlign: "center",
            align: "center",
            width: 80,
            valueGetter: (params) => (params.row?.fiche_horaire ? params.row?.fiche_horaire.images : false),
            renderCell: ({ value }) => displayWithIcon(value),
        },
    ];

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedHoraire, setSelectedHoraire] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleCellEditCommitHoraires = async (params) => {
        if (!editable) return;
        try {
            if (params.field === "fiche_horaire__remarque") {
                const res = await saveFicheHoraire({ id: params.row.fiche_horaire.id, remarque: params.value });
                if (res.status < 200 || res.status >= 400) throw new Error("saveFicheHoraire() returned an error status code");
                params.row.fiche_horaire.remarque = params.value;
            } else {
                const { data: horaire } = await getHoraire(params.id);
                horaire[params.field] = params.value;

                delete horaire.camion;
                delete horaire.commande;
                delete horaire.personnel;
                delete horaire.fiche_horaire;
                const res = await saveHoraire(horaire);
                if (res.status < 200 || res.status >= 400) throw new Error("saveHoraire() returned an error status code");
                if (params.row) params.row[params.field] = params.value;
            }
        } catch (e) {
            console.log(e);
            toast.error("Erreur dans la sauvegarde de l'horaire");
        }
    };

    const onDeleteHoraire = async (id) => {
        setOpenDialog(false);
        setLoading(true);
        toast.info("Suppresion en cours ...");
        try {
            const res = await deleteHoraire(id);
            if (res.status < 200 || res.status >= 400) throw new Error("deleteHoraire() returned an error status code");
        } catch (e) {
            console.log(e);
            toast.error("Erreur dans la suppression de l'horaire");
        }
        setLoading(false);
    };

    const onDeleteFicheHoraire = async (fiche_id) => {
        setOpenDialog(false);
        setLoading(true);
        toast.info("Suppression en cours ...");
        try {
            await deleteFicheHoraire(fiche_id);
        } catch (e) {
            console.log(e);
            toast.error("Impossible de supprimer un ou plusieurs horaires de la fiche");
            return;
        }
        setLoading(false);
        navigate("/horaires/saison");
    };

    const onHoraireRowClick = (e) => {
        if (e.field !== "id" || restricted) return;
        setSelectedHoraire(e.row);
        setOpenDialog(true);
    };

    if (commande) {
        horairesColumns.push({
            field: "commande",
            headerName: "Commande",
            headerAlign: "center",
            width: 250,
            valueGetter: ({ value }) => (value ? `N°${value.numero_commande} - ${value.client?.nom}` : ""),
            renderCell: (params) =>
                params?.value ? restricted ? params?.value : <Link to={`/commandes/view/${params?.row?.commande?.id}`}>{params?.value}</Link> : "",
        });
    }

    if (personnel) {
        horairesColumns.push({
            field: "personnel",
            headerName: "Personnel",
            headerAlign: "center",
            sortComparator: (a, b) => a?.displayableName?.localeCompare(b?.displayableName),
            width: 200,
            valueGetter: ({ value }) => (value?.displayableName ? value.displayableName : ""),
            renderCell: (params) => (params?.value ? <Link to={`/personnels/view/${params?.row?.personnel?.id}`}>{params.value}</Link> : ""),
        });
    }
    if (!restricted) {
        horairesColumns.push({
            field: "fiche_horaire__remarque",
            headerName: "Remarque",
            headerAlign: "center",
            editable: true,
            width: 220,
            valueGetter: (params) => (params.row?.fiche_horaire ? params.row?.fiche_horaire.remarque : ""),
            renderCell: ({ value }) => displayWithTooltip(value),
        });
    }

    return (
        <React.Fragment>
            <Backdrop
                open={loading}
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div style={{ height: height, width: "100%" }}>
                <DataGridPro
                    density={get_density()}
                    onStateChange={on_state_change}
                    components={{ Toolbar: GridToolbar }}
                    onCellEditCommit={handleCellEditCommitHoraires}
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    disableColumnFilter
                    rows={horaires}
                    columns={horairesColumns}
                    onCellClick={(params, event) => {
                        if (!event.ctrlKey) {
                            event.defaultMuiPrevented = true;
                            onHoraireRowClick(params);
                        }
                        return;
                    }}
                />
            </div>

            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                maxWidth="md"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Détails</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid
                            container
                            spacing={2}
                            // direction="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ my: 1 }}
                        >
                            <Grid item xs={12} md={12} align="center">
                                Fiche horaire n°{selectedHoraire?.fiche_horaire?.id} / horaire n°{selectedHoraire?.id}
                            </Grid>

                            {/* <Grid item xs={12} md={12} align="center">
								<Link to={`/horaires/fiche/${selectedHoraire?.fiche_horaire}`}>
									<Button
										variant="contained"
										color="success"
									>
										Voir les horaires liés à cette fiche horaire
									</Button>
								</Link>
							</Grid> */}
                            <Grid item xs={12} md={12} />
                            {editable && !restricted && (
                                <Grid item md={12}>
                                    <Accordion sx={{ mb: 2 }}>
                                        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                                            <Typography>Suppressions</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Alert severity="warning" sx={{ my: 2 }}>
                                                <AlertTitle>Attention</AlertTitle>
                                                Attention ! Les actions de suppression <strong>ne sont pas réversibles !</strong>
                                            </Alert>
                                            <Button
                                                variant="contained"
                                                color="error"
                                                sx={{ mr: 3 }}
                                                onClick={async () => await onDeleteFicheHoraire(selectedHoraire?.fiche_horaire?.id)}
                                            >
                                                Supprimer tous les horaires liés à cette fiche horaire
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="error"
                                                onClick={async () => await onDeleteHoraire(selectedHoraire?.id)}
                                            >
                                                Supprimer cet horaire
                                            </Button>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            )}
                        </Grid>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
