import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import auth from "../../services/authService";

const ProtectedModRoute = () => {
    const user = auth.getCurrentUser();
    return (!user || !user.is_staff) && !user.is_superuser ? <Navigate to="/login" /> : <Outlet />;
};

export default ProtectedModRoute;
