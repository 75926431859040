import http from "./httpService";
import { get_api_url } from "../components/utils"
import moment from "moment"
import { getJwt } from "./authService"
const apiEndpoint = `${get_api_url()}/deplacements`;

function deplacementUrl(id) {
    return `${apiEndpoint}/${id}/`;
}

export function getPlanning(year) {
    return http.get(`${get_api_url()}/planning/${year}/`);
}

export function getDeplacementsEquipe(idEquipe) {
    return http.get(`${apiEndpoint}/?ordering=startDate&equipe=${idEquipe}`);
}

export function getDeplacementsChef(saison_id, id_chef, depart = "", retour = "", visible = "") {
    return http.get(`${apiEndpoint}/?ordering=startDate&chefs__id=${id_chef}&depart__lte=${depart}&retour__gte=${retour}&saison=${saison_id}&chefs__id__isnull=false&visible=${visible}`);
}


export function updateDriveTime(id_deplacement) {
    return http.get(`${get_api_url()}/update-drive-time/${id_deplacement}?token=${getJwt()}`)
}

export function getDeplacements(year = null) {
    if (year) {
        return http.get(`${apiEndpoint}/?ordering=startDate&saison__year=${year}`);
    }
    return http.get(`${apiEndpoint}/`);
}

export function getDeplacement(deplacementId) {
    return http.get(deplacementUrl(deplacementId));
}


export async function get_deplacements_at_date(date) {
    return http.get(`${apiEndpoint}/?ordering=equipe__id&startDate__lte=${moment(date).format("YYYY-MM-DDT23:59")}&endDate__gte=${moment(date).format("YYYY-MM-DDT00:01")}`); 
}


export async function get_deplacement_equipe_interval(id_equipe, startDate, endDate) {
	const { data: start_date_in_interval } = await http.get(
		`${apiEndpoint}/?ordering=startDate&equipe__id=${id_equipe}&startDate__lte=${moment(
			startDate
		)
			.startOf("day")
			.format("YYYY-MM-DDTHH:mm")}&endDate__gte=${moment(startDate)
			.startOf("day")
			.format("YYYY-MM-DDTHH:mm")}`
	);
	const { data: end_date_in_interval } = await http.get(
		`${apiEndpoint}/?ordering=startDate&equipe__id=${id_equipe}&startDate__lte=${moment(
			endDate
		)
			.startOf("day")
			.format("YYYY-MM-DDTHH:mm")}&endDate__gte=${moment(endDate)
			.startOf("day")
			.format("YYYY-MM-DDTHH:mm")}`
	);

    // Filter to remove double deplacement
    return [...new Map([...start_date_in_interval, ...end_date_in_interval].map(item => [item?.id, item])).values()];
}

export function saveDeplacement(deplacement) {
    if (deplacement.id) {
        const body = { ...deplacement };
        return http.put(`${deplacementUrl(deplacement.id)}`, body);
    }

    return http.post(`${apiEndpoint}/`, deplacement);
}

export function deleteDeplacement(deplacementId) {
    return http.delete(deplacementUrl(deplacementId));
}


export function check_disponibilite(saison_id, deplacement_id, camions, personnels, equipe, depart, retour) {
    let datas = {
        saison: saison_id,
        deplacement_id: deplacement_id,
        camions: camions,
        personnels: personnels,
        equipe: equipe,
        depart: depart,
        retour: retour
    }
    return http.post(`${get_api_url()}/check-dispos`, datas);
}

export function camion_has_deplacement(saison_id, depart, retour, camion_id) {
    return http.get(`${apiEndpoint}/?ordering=startDate&saison=${saison_id}&depart__gte=${depart}&retour__lte=${retour}&camion__id=${camion_id}`);
}

export function equipe_has_deplacement(saison_id, depart, retour, equipe_id) {
    return http.get(`${apiEndpoint}/?ordering=startDate&saison=${saison_id}&depart__gte=${depart}&retour__lte=${retour}&equipe__id=${equipe_id}`);
}

export function export_to_dev() {
    return http.post(`${get_api_url()}/prod-to-dev/`);
}