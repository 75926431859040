import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
    AccountCircle,
    House,
    ExpandLess,
    ExpandMore,
    StarBorder,
    AttachMoneyOutlined,
    AccountBoxOutlined,
    Build,
    Inbox,
    Business,
    Public,
    Pets,
    EuroSymbol,
    Group,
    LocalShipping,
    SpeakerNotes,
    Receipt,
    Construction,
    Call,
    Euro,
    DateRange,
    Payments,
    AccessTime,
    FileUpload,
    Cake,
    LockOpen,
    MailOutline,
    PermContactCalendar,
} from "@mui/icons-material";
import { Divider, List, Collapse, ListSubheader, ListItemText, ListItemButton, ListItemIcon, ListItem } from "@mui/material";

import { FcStatistics } from "react-icons/fc";
import { RiHandCoinLine } from "react-icons/ri";
import { BsBinoculars } from "react-icons/bs";
import { MdOutlineCardTravel } from "react-icons/md";
import { FaMoneyBillAlt } from "react-icons/fa";

export const DrawerSaison = ({ saison }) => {
    const [open, setOpen] = React.useState(saison?.current_saison);
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <List
            sx={{
                width: 230,
                bgcolor: "background.paper",
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader component="div" onClick={handleClick}>
                    {open ? <ExpandLess /> : <ExpandMore />}
                    Saison {saison.year}
                </ListSubheader>
            }
        >
            <Collapse in={open} timeout="auto" unmountOnExit>
                {/* Commandes */}
                <ListItem component={Link} to={"/commandes/saison/" + saison.year}>
                    <ListItemIcon>
                        <Receipt />
                    </ListItemIcon>
                    <ListItemText primary="Commandes" />
                </ListItem>

                {/* Appels */}
                <ListItem key={"appels"} button component={Link} to={"/appels/saison/" + saison.year}>
                    <ListItemIcon>
                        <Call />
                    </ListItemIcon>
                    <ListItemText primary="Appels et avis" />
                </ListItem>

                {/* Planning */}
                <ListItem key={"planning"} component={Link} to={`/planning/${saison.year}`}>
                    <ListItemIcon>
                        <DateRange />
                    </ListItemIcon>
                    <ListItemText primary="Planning" />
                </ListItem>

                {/* Planning personnel */}
                <ListItem key={"plandning"} component={Link} to={`/planning-personnel/${saison.year}`}>
                    <ListItemIcon>
                        <PermContactCalendar />
                    </ListItemIcon>
                    <ListItemText primary="Planning des personnels" />
                </ListItem>

                {/* Planning camion */}
                <ListItem key={"planning-camions"} component={Link} to={`/planning-camions/${saison.year}`}>
                    <ListItemIcon>
                        <LocalShipping />
                    </ListItemIcon>
                    <ListItemText primary="Planning des camions" />
                </ListItem>

                {/* bons de facturations */}
                <ListItem key={"bons"} button component={Link} to={"/bons-facturation/saison/" + saison.year}>
                    <ListItemIcon>
                        <Payments />
                    </ListItemIcon>
                    <ListItemText primary="Bons de facturation" />
                </ListItem>

                {/* horaires */}
                <ListItem key={"horaires"} button component={Link} to={"/horaires/saison/" + saison.year}>
                    <ListItemIcon>
                        <AccessTime />
                    </ListItemIcon>
                    <ListItemText primary="Horaires" />
                </ListItem>

                {/* Personnels */}
                <ListItem component={Link} to={"/personnels/saison/" + saison.year}>
                    <ListItemIcon>
                        <AccountCircle />
                    </ListItemIcon>
                    <ListItemText primary="Personnels" />
                </ListItem>

                {/* Équipes */}
                <ListItem component={Link} to={"/equipes/saison/" + saison.year}>
                    <ListItemIcon>
                        <Group />
                    </ListItemIcon>
                    <ListItemText primary="Équipes" />
                </ListItem>

                {/* Camions */}
                <ListItem key={"camions"} button component={Link} to={"/camions/saison/" + saison.year}>
                    <ListItemIcon>
                        <LocalShipping />
                    </ListItemIcon>
                    <ListItemText primary="Camions" />
                </ListItem>

                {/* Prospection */}
                <ListItem key={"prospection"} button component={Link} to={"/prospection/saison/" + saison.year}>
                    <ListItemIcon>
                        <MailOutline />
                    </ListItemIcon>
                    <ListItemText primary="Prospection" />
                </ListItem>

                {/* remunerations */}
                <ListItem key={"remunerations"} button component={Link} to={`/remunerations/saison/${saison.year}`}>
                    <ListItemIcon>
                        <RiHandCoinLine />
                    </ListItemIcon>
                    <ListItemText primary="Rémunérations" />
                </ListItem>

                <ListItem key={"tickets"} component={Link} to={`/tickets/saison/${saison.year}`}>
                    <ListItemIcon>
                        <FaMoneyBillAlt />
                    </ListItemIcon>
                    <ListItemText primary="Tickets de caisse" />
                </ListItem>

                {/* Prix */}
                <ListItem key={"prix"} button component={Link} to={`/prix/saison/${saison.year}`}>
                    <ListItemIcon>
                        <EuroSymbol />
                    </ListItemIcon>
                    <ListItemText primary="Prix" />
                </ListItem>

                {/* Stats */}
                <ListItem key={"stats"} button component={Link} to={`/statistiques/${saison.year}`}>
                    <ListItemIcon>
                        <FcStatistics />
                    </ListItemIcon>
                    <ListItemText primary="Statistiques" />
                </ListItem>

                {/* Fiches visites */}
                <ListItem key={"fiches-visite"} button component={Link} to={`/fiches-visite/${saison.year}`}>
                    <ListItemIcon>
                        <BsBinoculars />
                    </ListItemIcon>
                    <ListItemText primary="Fiches visite" />
                </ListItem>

                <ListItem key={"fiches-fin-elevage"} button component={Link} to={`/fiches-fin-elevage/${saison.year}`}>
                    <ListItemIcon>
                        <MdOutlineCardTravel />
                    </ListItemIcon>
                    <ListItemText primary="Fiches fin d'élevage" />
                </ListItem>
            </Collapse>

            <Divider />
        </List>
    );
};
