import React from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Checkbox } from "@mui/material";

export function TextInput({ control, name, label, ...props }) {
	return (
		<Controller
			name={name}
			control={control}
			defaultValue={null}
			render={({ field: { onChange, value }, fieldState: { error } }) => {

				return (
					<TextField
						value={value}
						onChange={onChange}
						label={label}
						error={!!error}
						helperText={error ? error.message : null}
						InputLabelProps={{
							shrink: true,
						}}
						{...props}
					/>
				);
			}}
		/>
	);
}
