import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, Button, Skeleton, Alert, AlertTitle } from "@mui/material";
import { DatePickerInput } from "../common/DatePickerInput";
import { useForm, useWatch } from "react-hook-form";
import { get_deplacements_at_date } from "../../services/deplacementService";
import { getFicheCommande } from "../../services/ficheChaponnageService";
import { getOperationCommandeOperateur } from "../../services/operationService";
import { toast } from "react-toastify";
import moment from "moment";
import { ArrowBack, ArrowForward, GpsFixed } from "@mui/icons-material";
import AccordionDeplacement from "./accordion_deplacement";
import { getCurrentSaison } from "../../services/saisonService";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

export function HomepageAdmin() {
    const [current_date, set_current_date] = useState(moment());
    const [deplacements_to_display, set_deplacements_to_display] = useState([]);
    const navigate = useNavigate();

    document.title = "Chapi - Accueil admins";

    useEffect(() => {
        async function fetch_deplacement_at_date() {
            const { data: deplacements_found } = await get_deplacements_at_date(current_date);
            set_deplacements_to_display(deplacements_found);
        }

        if (current_date) fetch_deplacement_at_date();
    }, [current_date]);

    return (
        <Box sx={{ mx: 1 }}>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item md={12} xs={12}>
                    <Typography variant="h4">Récapitulatif journée</Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                    <Button startIcon={<GpsFixed />} variant="outlined" onClick={() => navigate("/equipes/position")}>
                        Position des équipes
                    </Button>
                </Grid>

                <Grid item md={12} container justifyContent="center" alignItems="center">
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                        }}
                    >
                        <Grid item xs={3} md={3} sx={{ mr: -1.5 }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<ArrowBack />}
                                onClick={() => set_current_date(moment(current_date).add(-1, "days"))}
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <MobileDatePicker
                                label={"Date"}
                                disableCloseOnSelect={false}
                                cancelText={"Annuler"}
                                todayText={"Aujourd'hui"}
                                inputFormat="ddd DD/MM/yyyy"
                                value={moment(current_date)}
                                onChange={(e) => set_current_date(moment(e))}
                                showTodayButton
                                renderInput={(params) => <TextField {...params} />}
                            />{" "}
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <Button
                                variant="outlined"
                                color="primary"
                                sx={{ ml: 1 }}
                                endIcon={<ArrowForward />}
                                onClick={() => set_current_date(moment(current_date).add(1, "days"))}
                            />
                        </Grid>
                    </div>
                </Grid>

                {deplacements_to_display?.length > 0 ? (
                    deplacements_to_display.map((deplacement) => <AccordionDeplacement deplacement={deplacement} current_date={current_date} />)
                ) : (
                    <Grid item xs={12} md={12}>
                        <Alert severity="info" sx={{ mt: 2 }}>
                            <AlertTitle>Aucun déplacement</AlertTitle>
                            C'est une journée calme ...
                        </Alert>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}
