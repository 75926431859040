import http from "./httpService";
import { get_api_url } from "../components/utils"
import moment from "moment"
const apiEndpoint = get_api_url() + "/planned-camions";


function planned_camion_url(id) {
	return `${apiEndpoint}/${id}/`;
}



export function get_deplacement_planned_camions(id_deplacement) {
	return http.get(
		apiEndpoint + "/?ordering=startDate&deplacement__id=" + id_deplacement
	);
}

export function get_planned_camions_at_date(id_deplacement, date) {
	return http.get(
		`${apiEndpoint}/?ordering=startDate&deplacement__id=${id_deplacement}&startDate__lte=${moment(date).format("YYYY-MM-DD")}&endDate__gte=${moment(date).format("YYYY-MM-DD")}`
	);
}

export async function get_planned_camion_interval(id_camion, startDate, endDate) {
	const { data: start_date_in_interval } = await http.get(`${apiEndpoint}/?ordering=startDate&camion__id=${id_camion}&startDate__lte=${moment(startDate).format("YYYY-MM-DDTHH:mm")}&endDate__gte=${moment(startDate).format("YYYY-MM-DDTHH:mm")}`)
	const { data: end_date_in_interval } = await http.get(`${apiEndpoint}/?ordering=startDate&camion__id=${id_camion}&startDate__lte=${moment(endDate).format("YYYY-MM-DDTHH:mm")}&endDate__gte=${moment(endDate).format("YYYY-MM-DDTHH:mm")}`)

	// Filter to remove double deplacement
	return [...new Map([...start_date_in_interval, ...end_date_in_interval].map(item => [item.deplacement?.id, item])).values()];
}

export function get_planned_camion_saison(year) {
	return http.get(
		apiEndpoint + "/?ordering=startDate&camion__saison__year=" + year
	);
}

export function get_planned_camion(planned_camion_id) {
	return http.get(planned_camion_url(planned_camion_id));
}

export function save_planned_camion(planned_camion) {
	if (planned_camion.id) {
		const body = { ...planned_camion };
		return http.put(planned_camion_url(planned_camion.id), body);
	}

	return http.post(`${apiEndpoint}/`, planned_camion);
}

export function delete_planned_camion(planned_camion_id) {
	return http.delete(planned_camion_url(planned_camion_id));
}
