import http from "./httpService";
import {get_api_url } from "../components/utils"


const apiEndpoint = get_api_url() + "/fiches-horaire";

function ficheHoraireUrl(id) {
    return `${apiEndpoint}/${id}/`;
}

export function getFichesHoraire(saison_year=null) {
    return http.get(`${apiEndpoint}/?ordering=date&saison__year=${saison_year}`);
}

export function getFichesHorairePersonnel(personnel_id) {
    return http.get(`${apiEndpoint}/?ordering=date&&horaires__personnel__id=${personnel_id}`);
}

export function getFicheHoraire(ficheHoraireId) {
    return http.get(ficheHoraireUrl(ficheHoraireId));
}

export function saveFicheHoraire(ficheHoraire) {
    if (ficheHoraire.id) {
        const body = { ...ficheHoraire };
        return http.put(`${ficheHoraireUrl(ficheHoraire.id)}`, body);
    }

    return http.post(`${apiEndpoint}/`, ficheHoraire);
}

export function deleteFicheHoraire(ficheHoraireId) {
    return http.delete(ficheHoraireUrl(ficheHoraireId));
}
